@mixin button-hover-state(
  $initial-colour,
  $amount: 10%
) {
  @if $btn-hover-style == darken {
    background-color: darken($initial-colour, $amount);
    border-color: darken($initial-colour, $amount);
  }

  @if $btn-hover-style == lighten {
    background-color: lighten($initial-colour, $amount);
    border-color: lighten($initial-colour, $amount);
  }
}

@function button-hover-state(
  $initial-colour,
  $amount: 10%
) {
  @if $btn-hover-style == darken {
    @return darken($initial-colour, $amount);
  }

  @if $btn-hover-style == lighten {
    @return lighten($initial-colour, $amount);
  }
}
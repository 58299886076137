// These are the small buttons that appear in things like
// increasing/decreasing an item's quantity in the shopping cart

@mixin button-icon (
  $btn-background-colour: $btn-background-colour,
  $btn-text-colour: $btn-text-colour
) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $btn-background-colour;
  color: $btn-text-colour;
  width: 20px;
  height: 20px;
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  font-size: 0;
  text-align: center;
  transition: all $btn-transition-duration $transition-curve;

  @if $btn-border-radius != 0 {
    border-radius: 100%;
  }

  &:before {
    font-size: .6rem;
    font-family: $icon-font-family;
    line-height: 1;
    font-weight: normal;
  }

  &:hover {
    color: $btn-text-colour;
    text-decoration: none;
    @include button-hover-state($btn-background-colour);
  }
}

a.buttonIcon {
  @include button-icon;
}

.increaseQuantity:before {
  content: '\f067'; // +
}

.decreaseQuantity:before {
  content: '\f068';
}

.buttonIcon.expand:before {
  content: '\f078';
}

.buttonIcon.remove:before,
.appliedFilters .remove:before {
  content: '\f00d';
}
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="url"],
select,
textarea {
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-base;
  line-height: $input-line-height;
  border: $input-border;
  border-radius: $input-border-radius;
  background-clip: padding-box;
  color: $body-colour;

  &::placeholder {
    color: $input-placeholder-colour;
  }
}

textarea {
  width: 100%;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="url"],
select {
  min-height: 38px; // Force match native select height
}

input[type="search"] {
  // This overrides the extra rounded corners on search inputs in iOS so that our
  // `.form-control` class can properly style them. Note that this cannot simply
  // be added to `.form-control` as it's not specific enough. For details, see
  // https://github.com/twbs/bootstrap/issues/11586.
  -webkit-appearance: none;
}

select {
  // Similar to above - this removes the 'gloss' style on Safari in OSX and IOS
  // https://stackoverflow.com/questions/7638677/how-can-i-remove-the-gloss-on-a-select-element-in-safari-on-mac
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat calc(100% - 0.75rem) 50%;
  background-color: #fff;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 2rem; // Clear the dropdown svg
}

select[multiple] {
  background: none;
}

// Generic form question styling
// i.e. label above input

.formQuestion {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;

  label {
    margin: 0 0 0.5em;
  }

  // escape this, how is this is a checkbox pair..?
  &.labelCheckBoxPair.contactYou {
    display: block;
    margin-right: 0;
  }

  .alert {
    order: 3;
    width: 100%;
  }

  // Set the input border to be red if invalidated
  .alert:not(.hidden),
  .alert:not(.hidden) + .error,
  .error:not(.hidden) {
    + input,
    + select {
      border-color: $danger-colour--on-white !important;
    }
  }
}

// Radio button with adjacent labels
.formQuestion.labelRadioPair,
.formQuestion.labelCheckBoxPair,
.formQuestion.contactReasonOption {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-right: 1rem;

  label {
    margin: 0;
  }
}

// We need to then send this reverse-row flex contents to the left!...
// Such inconsistent markup across the whole platform!
.formQuestion.contactReasonOption {
  justify-content: flex-end;
}

.formQuestion.labelRadioPair input[type="radio"],
.formQuestion.labelCheckBoxPair input[type="checkbox"] {
  margin-right: 0.5rem;
}

// Form groups
@mixin form-group {
  margin-bottom: $gap-width;
  padding: $gap-width;
  border: $border-width solid $border-colour;
  border-radius: $border-radius;

  @include max-width(sm) {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding: ($gap-width / 2) 0;
  }
}

.formWrapper fieldset {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: $border-width solid $border-colour;
}

// Specific class for 'look up address' or 'enter address' buttons
.postcode-lookup-buttons {
  display: block;
  margin-bottom: 15px;

  button {
    margin-bottom: 4px;
  }
}

// 'Donate' and 'Complete purchase' etc buttons
fieldset.registerButtons {
  text-align: center;
  font-size: $font-size-large;
  margin-top: $gap-width;
  margin-bottom: 0;

  button {
    margin: 0 0 ($gap-width / 2);
  }

  + .terms-and-conditions {
    // font-size: $font-size-small;
    margin: 0;
    text-align: center;
  }
}

// Other 'bottom' buttons (eg. /lobby/ResetMyPassword.aspx)
.formButtons {
  margin-top: $spacer;

  button {
    margin-top: 2px;
    margin-bottom: 2px;
  }
}

// This is the URL label that appears on /appeals/post form
// Forcing a word break here, because it's a label element that doesn't block well
// And the strings here are likely to be very long!
.page-url-indicator {
  word-break: break-all;
}

.terms-and-conditions-wrapper {
  text-align: center;

  // Recaptcha terms
  .grecaptcha-terms {
    font-size: $font-size-small;
    margin: 0;
    // text-align: center; // Moved to parent wrapper
  }
}

p.terms-and-conditions {
  font-size: $font-size-small;
}

// Honeypot field at bottom of custom forms
input.displayNone {
  display: none;
}

// Fix the weird autocomplete UL that appears below the title field (it's some JQuery UI thing)
ul.ui-autocomplete {
  background-color: #fff;
  list-style-type: none;
  padding-left: 0;
  width: 500px;
  max-width: 100%;
  li {
    padding: 5px 10px;
    &:hover {
      background-color: #C8C8C8;
    }
  }
}
// This appears at the top of an appeals listing page
// It lists some recent donors, including name; related appeal; and value

.donorsList {
  @if $donors-list-enabled == false {
    display: none;
  } @else {
    @include context-container;
    @include parentless-container-wrapper($container-max-width);
    font-size: $font-size-small;

    // "Help fund our vital work"
    // Not sure why this is split into two different elements
    > h2,
    > span {
      display: inline-block;
      font-size: $font-size-h5;
      margin-bottom: $gap-width / 2;
      font-weight: $headings-font-weight;
      font-family: $headings-font-family;
      color: $headings-colour;
    }

    ul {
      @include list-unstyled;
      display: flex;
      flex-wrap: wrap;
      margin: 0 ($gap-width * -1) ($gap-width * -1) 0;
    }

    li {
      width: 100%;
      max-width: 180px;
      margin: 0 $gap-width $gap-width 0;
      display: flex;
      align-items: flex-start;

      @if $donors-list-profile-pics-enabled == true {
        .profilePic {
          display: block;
          width: 40px;
          margin-right: $gap-width / 2;
        }
      }

      @else {
        .profilePic {
          display: none;
        }
      }


      .donorDetails {
        flex-grow: 1;

        > *:last-child {
          margin-bottom: 0;
        }

        // Donation value
        > span {
          font-weight: $font-weight-bold;
          font-size: $font-size-large;
        }
      }

      h3, h4 {
        font-size: 1em;
        margin-bottom: 5px;
      }
    }
  }
}
// A 'context container' is a boxed off group of information or fields
// Examples include:
// - donors list at the top of appeals listing,
// - find a fundraiser
// - filters etc.
// We've put some common styles in this placeholder class, which the relevant components can extend from

@mixin context-container (
  $context-container-padding: $context-container-padding,
  $context-container-border-radius: $context-container-border-radius,
  $context-container-background-colour: $context-container-background-colour
) {
  width: 100%;
  margin: 0 auto ($spacer * 2);
  padding: $context-container-padding;
  border-radius: $context-container-border-radius;
  background-color: $context-container-background-colour;
  color: text-contrast($context-container-background-colour);

  @include max-width(sm) {
    padding: $context-container-padding / 2;
  }
}
// Overall header background colour
.pageHeader {
  width: 100%;
  box-shadow: $header-box-shadow;
  transition: all $transition-duration $transition-curve;

  // Solid colour
  @if $header-background-gradient == none {
    background-color: $header-background-colour;
  }

  // Gradient
  @else {
    background: $header-background-gradient;
  }
}

.pageHeader .headerContent {
  width: 100%;
  max-width: $header-content-max-width;
  margin-top: $header-content-margin-top;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $header-content-margin-bottom;
  padding-top: $header-content-padding-top;
  padding-left: $header-content-padding-left;
  padding-right: $header-content-padding-right;
  padding-bottom: $header-content-padding-bottom;
  display: flex;
  align-items: center;

  .mainLogo {
    order: 1;
  }
  .tagline {
    order: 2;
  }
  .header-search {
    order: 3;
  }
  .mainCallToAction {
    order: 4;
  }

  > *:not(.mainLogo) {
    margin-top: $spacer / 2;
    margin-bottom: $spacer / 2;
  }

  @if $tagline-enabled == true {
    .tagline {
      flex-grow: 1;
    }
  } @else if $header-search-enabled == true {
    .header-search {
      flex-grow: 1;
    }
  } @else {
    .mainCallToAction {
      flex-grow: 1;
      justify-content: flex-end;
    }
  }
}

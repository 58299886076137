.postCreate,
.inMemoryCreate {
  .headerText {
    width: 100%;
    max-width: $post-content-max-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: $site-bleed;
    padding-right: $site-bleed;
  }
}

// Slightly different markup (of course) means this isn't needed here
.postCreate .breadcrumbWrapper {
  padding-left: 0;
  padding-right: 0;
}

.postCreate .blogDetails {
  max-width: 100%;
}

.postCreateForm,
.inMemoryCreateForm {
  width: 100%;
  max-width: $post-content-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $site-bleed;
  padding-right: $site-bleed;

  fieldset:not(.formButtons) {
    @include form-group;
  }

  legend {
    @extend h2;
    display: inline-block;
    width: auto;
    background: $white;
    margin: 0 0 0 -15px;
    padding: 0 15px;
  }
}

// The section headers have an expand icon here that does nothing
.postCreateForm legend a.buttonIcon {
  display: none;
}

.postCreateParentAppeal {
  margin-bottom: $gap-width;
}

.postCreateButtons,
.inMemoryCreateContinue,
.inMemoryCreateButtons {
  margin-top: $gap-width;
  text-align: center;

  .button {
    margin: 0 auto 15px;
    font-size: $font-size-large;
  }
}

.postCreateForm .registerWrapper {
  @include form-group;

  h2 {
    margin-top: 0;
  }
}

// Once we've submitted the initial form, we get this 'Thank you!' message
.commentSuccess {
  width: 100%;
  max-width: calc(#{$post-content-max-width} - #{$site-bleed * 2});
  margin-left: auto;
  margin-right: auto;
  @include hint(
    $success-colour--on-background,
    $success-colour--background,
    $font-size: 1em,
    $padding: 1em
  );

  > *:last-child {
    margin-bottom: 0;
  }
}

// And then when you click the link in the email, you get to this set a password page
.passwordForm {
  @include context-container;
  width: calc(100% - #{$site-bleed * 2});
  max-width: 700px;
  margin: 2rem auto;

  .contentBlockWrapper {
    margin: 0 0 ($spacer * 2);
  }

  .postContent {
    padding: 0;
  }

  label {
    display: flex;
    flex-wrap: wrap;
  }

  input {
    width: calc(100% - 1.5em);
    margin: 0.5em 0.5em 0 0;
  }

  // This is actually the mandatory asterisk
  .formQuestion .alert {
    width: 1em;
    margin: 0.5em 0 0;
  }
}

// And once you've set a password, you get this page:
// 'Thank you for activating your account. Please log in'
// There is no defining classes here, so I'm doing some risky selection to style this horrible page
// And then once you've logged in, you get to the accept terms page - which I'm also styling the same
body.lobby-ActivateMember,
body.lobby-AcceptTerms {
  #bodyForm > .contentBlockWrapper .contentBlock {
    max-width: 700px;
    margin: 0 auto;
    border-radius: $context-container-border-radius;
    background-color: $context-container-background-colour;
    padding: 1.5rem;
    text-align: center;

    .button {
      display: table; // New line
      margin: 10px auto 0;
    }
  }
}

body.lobby-AcceptTerms #bodyForm > .contentBlockWrapper .contentBlock {
  text-align: left;
}

// When it's not been lifted out the footer, then always hide it
.pageFooter .GlobalAlert {
  display: none;
}

.GlobalAlert {
  width: 100%;
  margin: 0;
  font-size: $font-size-small;
  text-align: center;
  padding: 0.75em $site-bleed;
  background-color: $global-alert-background-colour;
  color: $global-alert-colour;

  * {
    color: inherit;
  }

  a,
  a:link,
  a:visited {
    color: inherit;
  }

  a {
    text-decoration: underline;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.tabsWrapper {
  width: 100%;
  max-width: $container-max-width;
  margin: 0 auto $gap-width;
}

.listing .tabsWrapper {
  padding-left: $site-bleed;
  padding-right: $site-bleed;
}

.tabs {
  @include list-unstyled;
  display: flex;
  border-bottom: 2px solid $grey-lightest;
}

.tab {
  border-top-left-radius: .5em;
  border-top-right-radius: .5em;
  background-color: $grey-lightest;
  margin-right: 5px;
  transform: translateY(2px); // Over the border

  a {
    display: block;
    padding: $btn-padding-y $btn-padding-x;

    &:hover {
      text-decoration: none;
    }
  }

  &:hover {
    background-color: darken($grey-lightest, 10%);
  }

  &.active {
    background-color: $brand-primary;

    a {
      color: text-contrast($brand-primary);
    }
  }
}
// Config values
$platform-name: "bwhospitalscharity";
$assets-path: "../assets/";

// Font import
@font-face {
  font-family: 'Azo Super';
  src: url($assets-path + 'azo-super.woff2') format('woff2'),
       url($assets-path + 'azo-super.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url($assets-path + 'Roc Grotesk Regular.otf') format('opentype');
  font-weight: regular;
  font-style: normal;
}

// Colours
$_orange: #FF4900;
$_yellow: #FFE600;
$_blue-dark: #0F1733;
$_sand: #F4EFDB;

$brand-primary: $_orange;
$brand-primary-text-contrast-override: #FFF;
$brand-secondary: $_yellow;
$brand-secondary-text-contrast-override: $_blue-dark;

$donate-colour: $brand-secondary;

// Create greyscale
$black: #000;
$grey-dark: #292b2c;
$grey: #464a4c;
$grey-light: #636c72;
$grey-lighter: #eceeef;
$grey-lightest: $_sand;
$white: #fff;

// Layout
$container-max-width: 1170px;
$gap-width: 30px;
$site-bleed: 15px;

$border-radius: 2.5px;
$border-colour: $grey-lighter;
$box-shadow: none;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Misc
$body-bg: $white;
$body-colour: $_blue-dark;
$profile-pics-enabled: true; // Turn off donor, fundraiser, blog author photos sitewide
$border-width: 1px;
$date-ordinals-enabled: true; // Turn off 'st', 'nd', 'rd' from dates

// Transitions
$transition-duration: 0.1s;
$transition-duration-slow: 0.5s;
$transition-curve: ease;

// Logo
$logo: "logo.svg";
$logo-retina: $logo;
$logo-width: 318.89px;
$logo-height: 100px;
$logo-absolute: false; // true if we want the logo to vertically intersect with the menu
$logo-breakpoint: map-get($breakpoints, md);
$logo-mobile: $logo;
$logo-mobile-retina: $logo-mobile;
$logo-mobile-width: $logo-width * 0.75;
$logo-mobile-height: $logo-height * 0.75;

// Typography
$font-family-base: 'Roc Grotesk', sans-serif;
$font-size-base: 1rem;
$font-size-large: 1.25em;
$font-size-small: 0.875em;

$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$font-size-h1: 2.5em;
$font-size-h2: 2em;
$font-size-h3: 1.75em;
$font-size-h4: 1.5em;
$font-size-h5: 1.25em;
$font-size-h6: 1em;

$headings-margin-top: 0.5em;
$headings-margin-bottom: 0.5em;
$headings-font-family: 'Azo Super', sans-serif;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.2;
$headings-colour: inherit;
$headings-text-transform: uppercase;
$headings-letter-spacing: initial;

$hr-border-colour: $border-colour;
$hr-border-width: $border-width;

// Links
$link-colour: $brand-primary;
$link-decoration: none;
$link-decoration-colour: initial;
$link-hover-decoration: underline;
$link-hover-style: darken; // darken or lighten text links on hover

// Icon font
$icon-font-family: "FontAwesome";

// Buttons
$btn-background-colour: $_orange;
$btn-text-colour: text-contrast($btn-background-colour);
$btn-border-radius: $border-radius;
$btn-border-width: 0;
$btn-border-colour: $btn-background-colour;
$btn-box-shadow: $box-shadow;
$btn-padding-x: 1.8em;
$btn-padding-y: 0.9em;
$btn-font-family: $headings-font-family;
$btn-font-weight: $font-weight-bold;
$btn-font-size: inherit;
$btn-text-transform: uppercase;
$btn-text-decoration: none;
$btn-line-height: 1.25;
$btn-letter-spacing: inherit;
$btn-hover-style: darken; // darken or lighten the button on hover
$btn-transition-duration: $transition-duration;

// Donate button
$donate-btn-background-colour: $_yellow;
$donate-btn-colour: $_blue-dark;

// Social icons
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: $_orange; // A colour, or 'brand'
$social-icons-hover-colour: $social-icons-colour; // A colour, or 'brand'
$social-icons-width: 40px;
$social-icons-height: $social-icons-width;
$social-icons-font-size: 1.35rem;
$social-icons-gap: 2.5px;
$social-icons-border-radius: $social-icons-width;
$social-icons-box-shadow: none;

// Header specific
$social-icons-header-background-colour: $social-icons-background-colour; // A colour, transparent, or 'brand'
$social-icons-header-colour: text-contrast($social-icons-header-background-colour);
$social-icons-header-hover-colour: text-contrast($social-icons-header-background-colour);

// Footer specific social icons
$social-icons-footer-background-colour: $_orange;
$social-icons-footer-colour: text-contrast($social-icons-footer-background-colour);
$social-icons-footer-hover-colour: text-contrast($social-icons-footer-background-colour);

// Share this page
$share-enabled: true;
$share-text-align: left; // left, center, right
$share-label-font-family: $font-family-base;
$share-label-font-size: $font-size-h5;
$share-label-font-colour: $body-colour;

// Form elements
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-line-height: 1.25;
$input-colour: $body-colour;
$input-border-colour: #ced4da;
$input-border: 1px solid $input-border-colour;
$input-border-radius: $border-radius;
$input-placeholder-colour: $grey-light;

// Field groups
$field-group-label-width: 280px;

// Tables
$table-cell-padding: 0.75rem;

// Card
$card-gap-width: $gap-width;
$card-border-radius: $border-radius;
$card-border: none;
$card-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
$card-text-align: left;
$card-image-on: true;
$card-image-opacity: 1;
$card-image-background-colour: transparent;
$card-image-padding: 0;
$card-image-border-radius: 0;
$card-details-padding: #{$spacer * 1.25};
$card-details-background-colour: $_yellow;
$card-details-background-gradient: none;
$card-heading-font-family: $headings-font-family;
$card-heading-text-transform: $headings-text-transform;
$card-heading-colour: text-contrast($card-details-background-colour);
$card-heading-font-size: $font-size-h5;
$card-heading-margin-bottom: $spacer / 2;
$card-summary-enabled: true;
$card-summary-colour: text-contrast($card-details-background-colour);
$card-summary-font-size: $font-size-base;
$card-footer-on: false;
$card-footer-background-colour: $_sand;
$card-footer-background-gradient: none; // none or CSS gradient
$card-footer-colour: text-contrast($card-footer-background-colour);
$card-footer-padding: 0.5rem $card-details-padding;
$card-min-width: 270px;

// Card basic
$card-basic-image-position: above; // above, below

// Card text overlay
// $card-text-overlay-show-summary-on-hover: true;
// $card-text-overlay-summary-always-on: false;
// $card-text-overlay-details-max-width: 100%;
// $card-text-overlay-details-position-y: bottom; // top, center, bottom
// $card-text-overlay-details-position-x: left; // left, center, right
// $card-text-overlay-breakpoint: map-get($breakpoints, sm); // When do you want to break the text under image?

// Card text over
// $card-text-over-image-vertical-text-position: center; // top, center or bottom
// $card-text-over-image-darken-image-amount: 0.5;
// $card-text-over-image-tint-colour: $black;
// $card-text-over-image-show-summary-on-hover: true;

// Card side by side
// $card-side-by-side-image-position: left;
// $card-side-by-side-image-width: 50%;
// $card-side-by-side-grow-image: true; // Do you want the image to scale to 100% height of the overall card?
// $card-side-by-side-vertical-text-position: top; // top, center or bottom
// $card-side-by-side-breakpoint: map-get($breakpoints, sm); // When do you want to break the text under image?

// Card hover state
$card-hover-border-colour: $border-colour;
$card-hover-box-shadow: $card-box-shadow;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.075;
$card-hover-details-background-colour: $_orange;
$card-hover-heading-colour: $_blue-dark;
$card-hover-summary-colour: $_blue-dark;

// Event cards
$event-card-floating-date: false;
// $event-card-floating-date-background-colour: $card-details-background-colour;
// $event-card-floating-date-colour: text-contrast($event-card-floating-date-background-colour);
// $event-card-floating-date-border-radius: $border-radius;
// $event-card-floating-date-margin-y: $spacer;
// $event-card-floating-date-margin-x: $spacer;

// Global alert
$global-alert-background-colour: $brand-primary;
$global-alert-colour: text-contrast($global-alert-background-colour);

// Menu admin
$menu-admin-enabled: true;
$menu-admin-max-width: 100%;
$menu-admin-background-colour: transparent;
$menu-admin-background-gradient: none; // none or CSS gradient
$menu-admin-contents-max-width: $container-max-width; // 100%
$menu-admin-font-size: $font-size-small;
$menu-admin-align-items: right;
$menu-admin-link-colour: rgba($body-colour, 0.7);
$menu-admin-absolute: true;
$menu-admin-login-link-enabled: false;
$menu-admin-manager-link-enabled: false;
$menu-admin-my-details-link-enabled: false;
$menu-admin-logout-link-enabled: false;
$menu-admin-to-nav-breakpoint: map-get($breakpoints, lg);

// Header
$header-background-colour: $white;
$header-background-gradient: none;
$header-box-shadow: $box-shadow;

$header-absolute: false; // true if you want the header to appear transparently over banner images
// $header-absolute-background: linear-gradient(to bottom, rgba($black, 0.5), rgba($black, 0)); // Colour or gradient
// $header-absolute-breakpoint: map-get($breakpoints, sm); // When do you want to abandon the absolute header and return to solid?

// Header content
$header-content-max-width: $container-max-width;
$header-content-margin-top: $spacer;
$header-content-margin-bottom: $spacer;
$header-content-padding-top: $spacer * 1.25;
$header-content-padding-bottom: $spacer * 1.25;
$header-content-padding-left: $site-bleed;
$header-content-padding-right: $site-bleed;

// Tagline
$tagline-enabled: false;
// $tagline-font-family: $headings-font-family;
// $tagline-font-weight: $font-weight-bold;
// $tagline-font-size: $font-size-h5;
// $tagline-colour: $headings-colour;
// $tagline-above-cta-breakpoint: 0;
// $tagline-breakpoint: map-get($breakpoints, md);

// Main call to action
$cta-btn-background-colour: $btn-background-colour;
$cta-btn-colour: $btn-text-colour;
$cta-btn-gap: 7.5px;
// $cta-padding-right-at-nav-breakpoint: 6.5em; // The gap we need to leave so the cta buttons clear the burger menu button after nav breakpoint
$cta-btn-breakpoint: map-get($breakpoints, sm);
$cta-donate-on-appeal: false; // Do you want to show the main cta donate button when on appeal post/donate form?

// Search
$header-search-enabled: true;
// $header-search-margin-right: 7.5px;
// $header-search-breakpoint: map-get($breakpoints, lg);

// Search - input
// $header-search-input-max-width: 200px;
$header-search-input-height: 40px;
$header-search-input-margin-right: 0px;
// $header-search-input-padding: $input-padding-y $input-padding-x;
// $header-search-input-placeholder-colour: $input-placeholder-colour;
// $header-search-input-border-radius: $border-radius;
// $header-search-input-border-colour: $border-colour;
// $header-search-input-border-width: 1px 1px 1px 1px; // t-r-b-l
// $header-search-input-background-colour: $white;
// $header-search-input-colour: text-contrast($header-search-input-background-colour);
// $header-search-input-focus-background-colour: $white;
// $header-search-input-focus-colour: text-contrast($header-search-input-focus-background-colour);

// Search - button
// $header-search-button-width: 40px;
// $header-search-button-height: 40px;
// $header-search-button-background-colour: $btn-background-colour;
// $header-search-button-icon-font-size: $social-icons-font-size;
// $header-search-button-icon-colour: text-contrast($header-search-button-background-colour);
// $header-search-button-border-radius: $border-radius;

// Search when in nav
$nav-search-enabled: true;
$nav-search-input-margin-right: -40px;
$nav-search-input-padding: $input-padding-y $input-padding-x;
$nav-search-input-placeholder-colour: $input-placeholder-colour;
$nav-search-input-border-colour: $border-colour;
$nav-search-input-border-width: 0;
$nav-search-input-background-colour: $white;
$nav-search-button-background-colour: transparent;
$nav-search-button-icon-colour: $_orange;

// Social icons -- Colours set above (search for '$social-icons-header')
$header-social-icons-margin-right: 10px;
$header-social-icons-margin-bottom: 0;
$header-social-icons-above-cta-buttons: false;
$header-social-icons-breakpoint: map-get($breakpoints, lg);

// Sticky header
$header-sticky-enabled: true;
$header-sticky-background-colour: $header-background-colour;
$header-sticky-box-shadow: 0 0 10px rgba($black, 0.1);
$header-sticky-logo: $logo;
$header-sticky-logo-retina: $header-sticky-logo;
$header-sticky-logo-width: $logo-mobile-width;
$header-sticky-logo-height: $logo-mobile-height;
$header-sticky-menu-admin-enabled: false;
$header-sticky-search-enabled: false;
$header-sticky-social-icons-enabled: true;
$header-sticky-cta-btns-enabled: true;
$header-sticky-header-content-margin-top: 0;
$header-sticky-header-content-margin-bottom: 0;
$header-sticky-nav-margin-bottom: 0;

// Navigation
$nav-type: normal; // normal, flyout, fullscreen, mega
$nav-font-family: $font-family-base;
$nav-breakpoint: map-get($breakpoints, lg);
$nav-background-colour: $_sand;
$nav-text-transform: none;

// Top level items
$nav-top-level-item-padding: 12.5px 20px;
$nav-top-level-item-colour: text-contrast($nav-background-colour);
$nav-top-level-item-font-family: $nav-font-family;
$nav-top-level-item-font-size: $font-size-base;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-background-colour: transparent;
$nav-top-level-item-hover-colour: $nav-top-level-item-colour;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-chevrons-enabled: false;
$nav-top-level-chevrons-colour: $nav-top-level-item-colour;
$nav-top-level-first-item-home-icon: false;
// $nav-top-level-first-item-home-icon-font-size: 20px;

// Submenus
$nav-submenu-width: 220px;
$nav-submenu-background-colour: $_yellow;
$nav-submenu-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
$nav-submenu-border-radius: $border-radius;
$nav-submenu-item-padding: 12.5px 20px;
$nav-submenu-item-colour: text-contrast($nav-submenu-background-colour);
$nav-submenu-item-font-family: $nav-font-family;
$nav-submenu-item-font-size: $font-size-base;
$nav-submenu-item-font-weight: $font-weight-bold;
$nav-submenu-item-hover-colour: #fff;
$nav-submenu-item-hover-background-colour: $_orange;
$nav-submenu-chevrons-enabled: true;
$nav-submenu-chevrons-colour: $nav-submenu-item-colour;

// Burger button
$burger-btn-background-colour: $_sand;
$burger-btn-text-colour: text-contrast($burger-btn-background-colour);
$burger-btn-text-transform: $btn-text-transform;
$burger-btn-icon-breakpoint: null; // What viewport width do you want to remove the 'menu' text from the burger button?
$burger-btn-bar-breakpoint: null; // What viewport width do you want the burger button to pop under the header full width?

// Nav normal
$nav-normal-max-width: 100%;
$nav-normal-margin-top: 0;
$nav-normal-margin-bottom: 0;
$nav-normal-contents-max-width: $container-max-width;
$nav-normal-align-items: center;
$nav-normal-border-radius: $border-radius;
$nav-normal-mobile-background-colour: $_sand;
$nav-normal-mobile-top-level-item-colour: text-contrast($nav-normal-mobile-background-colour);
$nav-normal-mobile-submenu-background-colour: rgba($black, 0.08);
$nav-normal-mobile-submenu-item-colour: text-contrast($nav-normal-mobile-background-colour);
$nav-normal-mobile-chevrons-enabled: true;
$nav-normal-mobile-chevrons-colour: text-contrast($nav-normal-mobile-background-colour);

// Nav - Parent nav items clickable
$nav-parent-clickable-enabled: false;
// $nav-parent-clickable-chevron-background-colour: rgba(#fff, 0.75);
// $nav-parent-clickable-chevron-colour: $nav-normal-mobile-background-colour;
// $nav-parent-clickable-chevron-icon-type: "chevron"; // can take "chevron" or "plus"
// $nav-parent-clickable-chevron-active-background-colour: #fff;
// $nav-parent-clickable-chevron-active-colour: $nav-parent-clickable-chevron-colour;

// Nav - Mega menu
// $nav-mega-max-width: $container-max-width;
// $nav-mega-background-colour: $grey-lightest;
// $nav-mega-margin-top: $spacer;
// $nav-mega-border-radius: $border-radius;
// $nav-mega-box-shadow: none;
// $nav-mega-submenu-per-row: 3;
// $nav-mega-submenu-background-colour: $white;
// $nav-mega-submenu-item-colour: text-contrast($nav-mega-submenu-background-colour);
// $nav-mega-submenu-item-hover-colour: $nav-mega-submenu-item-colour;
// $nav-mega-submenu-item-hover-background-colour: rgba($black, 0.1);
// $nav-mega-submenu-border-radius: $border-radius;
// $nav-mega-submenu-heading-background-colour: $brand-primary;
// $nav-mega-submenu-heading-colour: text-contrast($nav-mega-submenu-heading-background-colour);
// $nav-mega-submenu-submenu-box-shadow: none;
// $nav-mega-submenu-heading-hover-background-colour: darken($nav-mega-submenu-heading-background-colour, 10%);
// $nav-mega-submenu-heading-hover-colour: $nav-mega-submenu-heading-colour;

// Specifically when no banner present and you have gone for $header-absolute: true
$no-banner-header-background-colour: $header-background-colour;
$no-banner-logo: $logo;
$no-banner-logo-retina: $no-banner-logo;
$no-banner-social-icons-header-background-colour: $social-icons-header-background-colour; // A colour, transparent, or 'brand'
$no-banner-social-icons-header-colour: $social-icons-header-colour; // A colour, or 'brand'
$no-banner-social-icons-header-hover-colour: $social-icons-header-hover-colour; // A colour, or 'brand'
$no-banner-nav-background-colour: $nav-background-colour;
$no-banner-nav-top-level-item-colour: $nav-top-level-item-colour;
$no-banner-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour;

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-margin-top: 0;
$carousel-margin-bottom: 0;
$carousel-image-overlay: null;
$carousel-contents-max-width: 100%;
$carousel-breakpoint: map-get($breakpoints, lg);
$carousel-details-style: overlay;
$carousel-details-position-y: center; 
$carousel-details-position-x: left;
$carousel-details-background-colour: $_orange;
$carousel-details-box-shadow: $box-shadow;
$carousel-details-max-width: 550px;
$carousel-details-min-height: auto;
$carousel-details-padding: $spacer * 2;
$carousel-details-margin-y: 0px;
$carousel-details-margin-x: $spacer * 2;
$carousel-details-border-radius: $border-radius;
$carousel-details-text-align: left;
$carousel-heading-text-transform: $headings-text-transform;
$carousel-heading-colour: #fff;
$carousel-heading-font-size: $font-size-h2;
$carousel-summary-enabled: true;
$carousel-summary-colour: #fff;
$carousel-summary-font-size: $font-size-base;
$carousel-read-more-enabled: true;
$carousel-read-more-background-colour: $_yellow;
// $carousel-read-more-border-colour: $btn-border-colour;
$carousel-read-more-colour: text-contrast($carousel-read-more-background-colour);
// $carousel-read-more-font-size: $carousel-summary-font-size;

// Carousel controls (left-right buttons)
$carousel-controls-enabled: true;
$carousel-controls-button-size: 40px;
$carousel-controls-button-gap: 0;
$carousel-controls-button-background-colour: transparent;
$carousel-controls-button-border-radius: $carousel-controls-button-size;
$carousel-controls-button-box-shadow: $box-shadow;
$carousel-controls-position-y: bottom;
$carousel-controls-position-x: right;
$carousel-controls-margin-y: $spacer;
$carousel-controls-margin-x: $spacer;
$carousel-controls-icon-colour: $btn-text-colour;
$carousel-controls-icon-font-size: 1.35em;
$carousel-controls-next-icon: "\f054"; // TODO: These are temporarily suspended
$carousel-controls-prev-icon: "\f053"; // TODO: These are temporarily suspended

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: true;
$carousel-buttons-type: dots;

// Carousel buttons dots
$carousel-dots-position-y: bottom;
$carousel-dots-position-x: center;
$carousel-dots-padding: $spacer * 2.5;
$carousel-dots-colour: $white;
$carousel-dots-opacity: 0.5;
$carousel-dots-active-colour: $_orange;

// Carousel buttons tabs
// $carousel-tabs-font-size: $font-size-h3;
// $carousel-tabs-background-colour: $grey-lighter;
// $carousel-tabs-colour: $brand-primary;
// $carousel-tabs-active-background-colour: $brand-primary;
// $carousel-tabs-active-colour: text-contrast($carousel-tabs-active-background-colour);
// $carousel-tabs-active-triangle-enabled: true;
// $carousel-tabs-text-align: left;
// $carousel-tabs-font-weight: $btn-font-weight;
// $carousel-tabs-text-transform: $btn-text-transform;
// $carousel-tabs-padding-x: $spacer;
// $carousel-tabs-padding-y: $spacer;
// $carousel-tabs-separator-width: 0px; // Width of border between tabs
// $carousel-tabs-separator-colour: $border-colour;

// Carousel video play button (before breakpoint, when carousel details present)
$carousel-video-btn-position-y: 50%; // What percentage do you want the play button positioned vertically?
$carousel-video-btn-position-x: 50%; // What percentage do you want the play button positioned laterally?

// Carousel scroll button
$carousel-scroll-btn-enabled: false;
// $carousel-scroll-btn-size: $carousel-controls-button-size;
// $carousel-scroll-btn-background-colour: $btn-background-colour;
// $carousel-scroll-btn-border-radius: $border-radius;
// $carousel-scroll-btn-box-shadow: $box-shadow;
// $carousel-scroll-btn-icon-colour: $btn-text-colour;
// $carousel-scroll-btn-icon-font-size: 1em;
// // $carousel-scroll-btn-icon: '\f078'; // TODO, similar issue to $carousel-controls-next-icon
// $carousel-scroll-btn-margin-y: 15%; // Can use a negative value to get it to peek over the edge
// $carousel-scroll-btn-animation-prefab: 0; // Pick a number from 0 (off) to 3!

// Carousel background YouTube video
$carousel-yt-background-enabled: false;
$carousel-yt-background-size: "cover"; // 'cover' or 'contain'
$carousel-yt-cover-padding-bottom: 56.25%; // Play with this to insure you get proper coverage. This is a dark art, see https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed. I'm giving you control here to play around with it.

// Carousel after breakpoint
$carousel-mobile-details-background-colour: $carousel-details-background-colour;
$carousel-mobile-heading-colour: text-contrast($carousel-mobile-details-background-colour);
$carousel-mobile-summary-colour: text-contrast($carousel-mobile-details-background-colour);

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios
$banner-min-height: auto;

// Home intro
$home-intro-max-width: 100%;
$home-intro-background-colour: transparent;
$home-intro-background-gradient: none;
$home-intro-background-image: null;
$home-intro-background-image-opacity: 0.5;
$home-intro-box-shadow: $box-shadow;
$home-intro-content-max-width: 1100px;
$home-intro-content-align: center;
$home-intro-padding-y: $spacer * 5;
$home-intro-margin-top: 0;
$home-intro-margin-bottom: 0;
$home-intro-text-align: center;
$home-intro-text-transform: none;
$home-intro-font-family: $font-family-base; // Will affect all elements within the home intro snippet
$home-intro-font-size: $font-size-h3;
$home-intro-font-weight: $font-weight-base;
$home-intro-line-height: $line-height-base;
$home-intro-colour: text-contrast($home-intro-background-colour);
$home-intro-border-radius: $border-radius;
$home-intro-mobile-padding-y: $home-intro-padding-y * .7;
$home-intro-mobile-font-size: $home-intro-font-size * .85;

// Home headlines intro
$home-headlines-intro-enabled: true;
$home-headlines-intro-snippet-title-enabled: false;
$home-headlines-intro-max-width: 100%;
$home-headlines-intro-content-max-width: $container-max-width;
$home-headlines-intro-background-colour: transparent;
$home-headlines-intro-padding-top: $spacer * 5;
$home-headlines-intro-padding-bottom: 0;
$home-headlines-intro-margin-top: 0;
$home-headlines-intro-margin-bottom: 0;
$home-headlines-intro-text-align: center;
$home-headlines-intro-text-transform: none;
$home-headlines-intro-font-family: $font-family-base;
$home-headlines-intro-font-size: $font-size-base;
$home-headlines-intro-colour: text-contrast($home-headlines-intro-background-colour);

// Home features
$home-features-max-width: $container-max-width;
$home-features-background-colour: $white;
$home-features-padding-top: $spacer * 2.5;
$home-features-padding-bottom: $spacer * 5;
$home-features-cards-entry-animation-prefab: 0; // See https://github.com/RaisingITDev/fresco/wiki/entry-animations
$home-features-cards-entry-animation-stagger: true;
$home-features-cards-entry-animation-stagger-count: 3;

// Impact stats
$impact-stats-enabled: true;
$impact-stats-max-width: 100%;
$impact-stats-margin-top: 0;
$impact-stats-margin-bottom: 0;
$impact-stats-padding-y: $spacer * 7;
$impact-stats-background-colour: $_yellow;
$impact-stats-background-gradient: none;
// $impact-stats-background-image: 'homepage-stats-bg.jpg';
$impact-stats-background-image-opacity: 0.25;
$impact-stats-background-image-size: cover;
$impact-stats-background-image-repeat: no-repeat;
$impact-stats-border-radius: 0;
$impact-stats-colour: text-contrast($impact-stats-background-colour);
$impact-stats-text-align: center; // left, center, right
$impact-stats-content-max-width: $container-max-width;
$impact-stats-breakpoint: map-get($breakpoints, md); // When do you want to break the overall layout from row to column?

$impact-stats-overall-container-direction: column; // column, row for the .homeImpactWrapper
$impact-stats-direction: row; // Do you want the stats in a row or column?
$impact-stats-mobile-text-align: $impact-stats-text-align;

// Heading
$impact-stats-heading-enabled: false;
// $impact-stats-heading-font-size: $font-size-h2;
// $impact-stats-heading-text-align: $impact-stats-text-align; // left, center, right
// $impact-stats-heading-colour: inherit;
// $impact-stats-heading-margin-bottom: $spacer * 3;
// $impact-stats-heading-text-transform: $headings-text-transform;

// Individual stat
$impact-stat-min-width: 180px; // Use this to control how many you get in a row
$impact-stat-direction: column; // column, row
$impact-stat-padding: 0px 0px;
$impact-stat-margin: $gap-width;
$impact-stat-border: 0px solid $border-colour; // Style border here
$impact-stat-border-width: 0 0 0 0; // Set border widths (t-r-b-l) here

// Figure
$impact-stats-figure-colour: $impact-stats-colour;
$impact-stats-figure-font-family: $headings-font-family;
$impact-stats-figure-font-size: 2.45em;

// Summary
$impact-stats-summary-colour: i$impact-stats-colour;
$impact-stats-summary-font-size: $font-size-base;
$impact-stats-summary-font-weight: $font-weight-base;
$impact-stats-summary-margin-top: $spacer;

// Home feeds
$home-feeds-max-width: $container-max-width;
$home-feeds-background-colour: $white;
$home-feeds-padding-top: $spacer * 7;
$home-feeds-padding-bottom: $spacer * 7;
$home-feeds-cards-entry-animation-prefab: 0; // See https://github.com/RaisingITDev/fresco/wiki/entry-animations
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 3;

// Feeds title
$feeds-title-font-family: $headings-font-family;
$feeds-title-font-size: 3rem;
$feeds-title-colour: $_orange;
$feeds-title-text-align: center;
$feeds-title-text-transform: $headings-text-transform;

// Feed item
$feed-item-read-more-enabled: false;

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: true;

// Twitter feed
$home-feed-twitter-enabled: false;
$home-feed-twitter-card-gap-width: $card-gap-width;
$home-feed-twitter-card-border: $card-border;
$home-feed-twitter-card-border-radius: $card-border-radius;
$home-feed-twitter-card-box-shadow: $card-box-shadow;
$home-feed-twitter-card-text-align: $card-text-align;
$home-feed-twitter-card-padding: $card-details-padding;
$home-feed-twitter-card-background-colour: $card-details-background-colour;
$home-feed-twitter-card-colour: text-contrast($home-feed-twitter-card-background-colour);
$home-feed-twitter-card-min-width: $card-min-width;

// Footer
$footer-boxes-count: 3;
$footer-prefab: 4;
$footer-max-width: 100%;
$footer-contents-max-width: $container-max-width;
$footer-margin-y: 0;
$footer-padding-y: $spacer * 2;
$footer-background-colour: $_sand;
$footer-background-gradient: none; // none or CSS gradient
$footer-colour: text-contrast($footer-background-colour);
$footer-link-colour: text-contrast($footer-background-colour);
$footer-link-decoration: none;
$footer-link-hover-colour: darken($footer-link-colour, 10%);
$footer-link-hover-decoration: underline;
$footer-text-align: center;
$footer-font-size: $font-size-base;
$footer-headings-font-size: inherit;
$footer-headings-text-transform: $headings-text-transform;

// Footer admin links
$footer-admin-basket-link-enabled: true;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: true;
$footer-admin-my-details-link-enabled: true;
$footer-admin-logout-link-enabled: true;

// Newsletter
$newsletter-background-colour: $_sand;
$newsletter-background-gradient: none; // none or CSS gradient
$newsletter-colour: text-contrast($newsletter-background-colour);
$newsletter-heading-font-family: $headings-font-family;
$newsletter-heading-font-size: $font-size-h3;
$newsletter-heading-text-align: center; // left, center, right
$newsletter-heading-text-transform: $headings-text-transform;
$newsletter-heading-margin-bottom: $spacer;
$newsletter-heading-colour: $newsletter-colour;
$newsletter-input-background-colour: $white;
$newsletter-button-height: 38px; // Hard code this so you can match it with the inputs
$newsletter-button-background-colour: $_orange;
$newsletter-button-colour: text-contrast($newsletter-button-background-colour);
$newsletter-button-border-colour: $newsletter-button-background-colour;
$newsletter-use-placeholders: false; // Turn on to use placeholders in the inputs rather than labels
$newsletter-required-label-enabled: false;

// Newsletter hero - full width form above rest of footer
$newsletter-hero-padding-y: $spacer * 3;
$newsletter-hero-contents-max-width: $footer-contents-max-width;

// Context container
$context-container-background-colour: $grey-lightest;
$context-container-padding: $spacer * 2;
$context-container-border-radius: $border-radius;

// Donors list
$donors-list-enabled: true;
$donors-list-profile-pics-enabled: $profile-pics-enabled;

// Posts
$post-content-max-width: 1020px;
$post-body-background-colour: transparent;

// Header text (container for breadcrumb and page title)
$header-text-margin-top: $spacer * 3;
$header-text-margin-bottom: $spacer * 1.5;

// Page title
$page-title-font-size: $font-size-h1;
$page-title-font-family: $headings-font-family;
$page-title-font-weight: $headings-font-weight;
$page-title-colour: $headings-colour;
$page-title-text-transform: $headings-text-transform;
$page-title-text-align: left; // left, center or right
$page-title-margin-top: 0;
$page-title-margin-bottom: $headings-margin-bottom;
$page-title-md-font-size: $page-title-font-size * 0.8; // <= $breakpoints.md
$page-title-sm-font-size: $page-title-font-size * 0.6; // <= $breakpoints.sm

// Page title over banner
$page-title-over-banner-enabled: false;
// $page-title-over-banner-breakpoint: map-get($breakpoints, md);
// $page-title-over-banner-background-colour: $brand-primary; // Add a background colour so that title is always legible as it appears over a photo
// $page-title-over-banner-colour: text-contrast($page-title-over-banner-background-colour);
// $page-title-over-banner-breadcrumb-enabled: true; // Toggle breadcrumbs - this CAN work but also some people might think looks a bit weird

// Sidebar
$sidebar-enabled: false;
$sidebar-layout: adjacent;
$sidebar-mobile-layout: below;
$sidebar-adjacent-width: 230px;
$sidebar-adjacent-gap: $spacer*2;
$sidebar-background-colour: $_yellow;
$sidebar-padding: $spacer;
$sidebar-border-radius: $context-container-border-radius;

// Associated lists
$associated-latest-enabled: true;
$associated-related-enabled: true;
$associated-most-read-enabled: false;
$associated-tag-cloud-enabled: false;
$associated-max-number-of-posts: 3;
$associated-list-heading-font-size: $font-size-h4;
$associated-item-image-enabled: false;
$associated-item-heading-font-size: $font-size-base;
$associated-item-heading-colour: $link-colour;
$associated-item-headings-text-transform: $headings-text-transform;
$associated-item-summary-enabled: false;
$associated-item-border: true;

// Listing
$listing-content-max-width: $container-max-width;
$listing-intro-max-width: $listing-content-max-width;
$listing-body-background-colour: transparent;
$listing-cards-per-row-desktop: 3;
$listing-cards-per-row-tablet: 2;
$listing-card-min-width: $card-min-width;

// Listed post settings
$listed-post-read-more-enabled: false;
$listed-news-post-publish-date-enabled: false;
$listed-blog-post-publish-date-enabled: false;
$listed-blog-post-author-enabled: false;
$listed-event-post-start-date-enabled: true;
$listed-event-post-location-enabled: true;
$listed-appeal-totaliser-enabled: false;


// Breadcrumb
$breadcrumb-enabled: true;
$breadcrumb-disabled-on-listing: false; // Opt out of showing breadcrumbs on listing pages, if breadcrumbs are enabled
$breadcrumb-margin-top: $spacer;
$breadcrumb-margin-bottom: $spacer;
$breadcrumb-max-width: $post-content-max-width;
$breadcrumb-text-align: $page-title-text-align; // left, center or right

// Blockquote
$blockquote-max-width: $post-content-max-width;
$blockquote-background-colour: transparent;
$blockquote-background-gradient: none;
$blockquote-border-radius: 0;
$blockquote-margin-y: $spacer * 2;
$blockquote-padding: 0;
$blockquote-text-align: left;
$blockquote-font-size: $font-size-large;
$blockquote-font-family: $font-family-base;
$blockquote-font-weight: $headings-font-weight;
$blockquote-line-height: $line-height-base;
$blockquote-colour: $_orange;
$blockquote-box-shadow: none;

// Fundraising
$fundraising-enabled: true; // Quickly turn off fundraising features

// Find a fundraiser widget
$find-a-fundraiser-widget-enabled: true;
$find-a-fundraiser-profile-pics-enabled: $profile-pics-enabled;

// Top fundraisers
$top-fundraisers-enabled: true;
$top-fundraisers-profile-pics-enabled: $profile-pics-enabled;


// Donation form
$donation-form-layout: rows;
$donation-form-banner-enabled: true;
$donation-form-frequency-tabs: true;
$donation-form-default-complete-message-enabled: true;

// Donation amounts
$donation-amount-min-width: $card-min-width; // IF using columns layout, set a min-width value on the amounts to dictate how many columns you want. Default will be 3 (items grow to fill row)
$donation-amount-figure-font-size: $font-size-h2;
$donation-amount-figure-colour: $donate-colour;
$donation-amount-thumbnail-enabled: true;

// Quick giving panel
$quick-giving-type: rows;
$quick-giving-background-colour: $context-container-background-colour;
$quick-giving-background-gradient: none; // none or CSS gradient
$quick-giving-padding: $context-container-padding;
$quick-giving-border-radius: $context-container-border-radius;
$quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size * 0.75;
$quick-giving-donation-amount-padding: 0.5rem 1rem;
$quick-giving-donation-amount-background-colour: $white;
$quick-giving-donation-amount-figure-colour: text-contrast($quick-giving-donation-amount-background-colour);
$quick-giving-donation-amount-border-width: $btn-border-width;
$quick-giving-donation-amount-border-colour: $donate-colour;
$quick-giving-donation-amount-selected-background-colour: $donate-colour;
$quick-giving-donation-amount-selected-figure-colour: text-contrast($quick-giving-donation-amount-selected-background-colour);

// Home quick giving panel
// $home-quick-giving-enabled: true;
// $home-quick-giving-type: simple; // simple, adjacent or overlay
// $home-quick-giving-max-width: 100%;
// $home-quick-giving-content-max-width: 100%;
// $home-quick-giving-margin-top: 0;
// $home-quick-giving-margin-bottom: 0;
// $home-quick-giving-padding-y: $spacer * 5;
// $home-quick-giving-background-colour: $quick-giving-background-colour;
// $home-quick-giving-background-gradient: $quick-giving-background-gradient; // none or CSS gradient
// $home-quick-giving-background-image: null;
// $home-quick-giving-background-image-opacity: 0.5;
// $home-quick-giving-donation-amount-background-colour: $donate-colour;
// $home-quick-giving-donation-amount-figure-colour: text-contrast($home-quick-giving-donation-amount-background-colour);
// $home-quick-giving-donation-amount-border-width: $btn-border-width; // Only comes into play with transparent background
// $home-quick-giving-donation-amount-border-colour: $donate-colour;
// $home-quick-giving-donation-amount-selected-background-colour: $white;
// $home-quick-giving-donation-amount-selected-figure-colour: text-contrast($home-quick-giving-donation-amount-selected-background-colour);
// $home-quick-giving-donate-btn-background-colour: $btn-background-colour;
// $home-quick-giving-donate-btn-colour: $btn-text-colour;
// $home-quick-giving-donate-btn-border-colour: $btn-border-colour;
// $home-quick-giving-donate-btn-font-size: $quick-giving-donation-amount-figure-font-size;
// $home-quick-giving-colour: $body-colour; // This should cover any headings and text you have in the snippet
// $home-quick-giving-heading-colour: $headings-colour;
// $home-quick-giving-heading-font-size: $font-size-h2;

// Adjacent homepage quick giving
// $home-quick-giving-type-adjacent-image-side: left; // If type is adjacent, which side do you want the image

// Overlay homepage quick giving
// $home-quick-giving-type-overlay-items-side: left; // If type is overlay, which side do you want the copy and donation items?
// $home-quick-giving-type-overlay-gradient: linear-gradient(to left, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.75) 100%);

// Button actions
$button-actions-padding: 0;
$button-actions-background-colour: transparent;
$button-actions-align-buttons: left;

// Totalisers
$totaliser-enabled: true;
$totaliser-layout: horizontal; // vertical or horizontal
$totaliser-width: 60px;
$totaliser-panel-background-colour: transparent;

// Comments
$comment-add-image-enabled: true;
$comment-add-video-enabled: false;

// Blog details
$blog-details-enabled: true;
$blog-details-background-colour: $context-container-background-colour;
$blog-details-padding: $context-container-padding;
$blog-details-border-radius: $context-container-border-radius;
$blog-details-profile-pic-enabled: $profile-pics-enabled;

// FAQs
$faq-content-max-width: $post-content-max-width;
$faq-item-header-background-colour: $_yellow;
$faq-item-header-padding: $context-container-padding;
$faq-item-header-border-radius: $context-container-border-radius;
$faq-item-header-font-size: $font-size-h4;
$faq-item-btn-background-colour: $btn-background-colour;
$faq-item-btn-chevron-colour: $btn-text-colour;
// $faq-item-hover-btn-background-colour: button-hover-state($faq-item-btn-background-colour);
// $faq-item-hover-btn-chevron-colour: text-contrast($faq-item-hover-btn-background-colour);

// In Memory
$listing-inmem-masonry-layout: false;

// Shop
$shop-enabled: true; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)
$basket-link-only-on-shop-pages: false; // Set to true to only show the basket link (in both header and footer admin menus) on 'shop' related pages only

// Departments list
$department-list-width: $sidebar-adjacent-width;
$department-list-background-colour: $context-container-background-colour;
$department-list-padding: $context-container-padding;
$department-list-border-radius: $context-container-border-radius;

// Product-price
$product-price-colour: $brand-secondary;
$product-price-font-size: $font-size-h4;

// Product post
$product-post-content-max-width: $post-content-max-width;

// Product rating
$product-rating-enabled: true;

// Cookie consent
$cookie-consent-position: bottom; // Bottom or top
$cookie-consent-background-colour: $black; // You can use rgba here for opacity
$cookie-consent-colour: text-contrast($cookie-consent-background-colour);
$cookie-consent-font-family: initial;
$cookie-consent-btn-background-colour: $btn-background-colour;
$cookie-consent-btn-text-colour: $btn-text-colour;

// Keeping In Touch
$keeping-in-touch-enabled: true;
$keeping-in-touch-via-email-enabled: true;
$keeping-in-touch-via-telephone-enabled: true;
$keeping-in-touch-via-sms-enabled: true;
$keeping-in-touch-via-post-enabled: true;

// Slick
$slick-enabled: false;
//
// Associated box
//

.associatedBox {
  // Image
  > a:not(.button) {
    display: block;
    width: 100%;
    border-radius: $border-radius;
    overflow: hidden;
    margin-bottom: $spacer / 2;

    .banner {
      display: block;
    }
  }

  // Heading
  .associatedTitle {
    font-size: $font-size-h3;
  }

  // Summary
  .associatedSummary {
    font-size: $font-size-small;
  }

  // Read more
  .readMore {
    display: none; // Hate it
  }

  // Footer
  .postFooterWrapper {
    display: none; // Never gonna look good
  }

  .button {
    margin: 0 5px 5px 0;
  }
}
.findAFundraiserWrapper {
  width: 100%;
  max-width: $container-max-width;
  margin: 0 auto;
  padding: 0 $site-bleed;
}

.findAFundraiser {
  @include context-container;

  .setUpFundraiserBtn {
    margin-right: 0;
    margin-bottom: $gap-width;
    font-size: $font-size-large;
  }

  .findAFundraiserBtn {
    margin-top: $gap-width / 2;
    margin-right: 0;
  }
}

.findAFundraiserForm {

  legend {
    font-size: $font-size-h4;
  }

  .formQuestion {
    display: inline-flex;
    flex-direction: column;
    width: calc((100% - #{$gap-width}) / 2 - 5px); // inline compensation
    margin-top: $spacer / 2;
    margin-bottom: $spacer / 2;

    &.findAFundraiserName {
      margin-right: $gap-width;
    }
  }

  // Force the name search and event dropdown to be equal height
  input, select {
    height: 50px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

// If we have sidebars set to appear ABOVE the content,
// then we kind of want to get rid of the find a fundraiser widget always
// because it pushes the actual appeal content down the page
@if $sidebar-layout == above {
  .appealWidgets .findAFundraiserWidget {
    display: none;
  }
}

//
// Responsive
//

@include max-width(sm) {
  .findAFundraiserForm {
    .formQuestion {
      width: 100%;
      padding: 0;
      &.findAFundraiserName {
        margin-right: 0;
      }
    }

    input, select {
      height: 40px;
    }
  }
}
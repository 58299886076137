// Include this mixin in the client's bespoke.
// This is for pre-configured designs, so you should just set the class and appeal type.
// Options for appeal-type are currently:
// "christmas"
// Don't forget to also set the JavaScript variable "lualBodyClasses" in the client's main.js to an array with all the classes.

@mixin appeal-field(
  $body-class,
  $appeal-type: null,
  $background-colour: null,
  $background-colour-top: null, // colour of top of bg linear gradient when bg image gets pushed down by text added via wysiwyg
  $background-image: null,
  $post-text-colour: null,
  $comment-text-colour: null,
  $icon-image: null,
  $icon-size: null,
  $icon-animation: null,
  $appeal-content-colour: null
) {

  @if (str-index($body-class, "Category_")) {
    $body-class: $body-class;
  } @else {
    $body-class: "appeal-" + $body-class;
  }


  @if $appeal-type=="christmas" {
    $background-colour: #fff;
    $background-colour-top: #072e47;
    $background-image: 'christmas-field.png';
    $post-text-colour: $white;
    $comment-text-colour: $body-colour;
    $icon-image: 'star.svg';
    $icon-size: 90px;
    $icon-animation: glitter 4s linear 0s infinite normal;
    $appeal-content-colour: $brand-secondary;

    body.#{$body-class} {
      #bodyForm {
        background-position: center -380px;
        background-size: 2450px auto;
        @include max-width(lg) {
          background-size: 2000px auto;
        }
        @include max-width(md) {
          background-size: 1400px auto;
        }
      }

      .commentsListWrapper .commentsList {
        min-height: 350px;
        margin: 0 auto 400px;

        @include max-width(lg) {
          min-height: 300px;
          margin: 0 auto 350px;
        }

        @include max-width(md) {
          min-height: 225px;
          margin: 0px auto 220px;
        }
      }
    }
  }


  @if $appeal-type == "stars" {
    $background-colour: #121212;
    $background-colour-top: #121212;
    $background-image: 'light-up-a-life-bg.jpg';
    $post-text-colour: $white;
    $comment-text-colour: $white;
    $icon-image: 'star.svg';
    $icon-size: 90px;
    $icon-animation: glitter 4s linear 0s infinite normal;
    $appeal-content-colour: $brand-secondary;

    body.#{$body-class} {
      #bodyForm {
        background-position-x: center;
        background-size: 1180px;
        @include max-width(md) {
          background-size: 815px;
        }
      }

      .commentsListWrapper .commentsList {
        min-height: 440px;
        margin: 0 auto 180px;
        @include max-width(md) {
          min-height: 400px;
          margin: 0 auto 150px; 
        }
      }
    }
  }


  @if $appeal-type == 'flowers' {
    $background-colour: #B3D773;
    $background-colour-top: #bceff2;
    $background-image: 'bg_meadow.jpg';
    $post-text-colour: $body-colour;
    $comment-text-colour: $body-colour;
    $icon-image: 'tulip.png';
    $icon-size: 70px;
    $icon-animation: boogie 4s linear 0s infinite normal;
    $appeal-content-colour: $brand-secondary;
    
    body.#{$body-class} {    
      
      #bodyForm {
        background-position: center top;
        background-size: 1560px auto;
      }

      .commentsListWrapper .commentsList {
        min-height: 225px;
        margin: 0 auto 150px;
      }

      .commentsList .commentDonation:nth-child(2n):before {
        background-image: url($assets-path + 'daisyB.png');
      }
      .commentsList .commentDonation:nth-child(3n):before {
        background-image: url($assets-path + 'cornflower.png');
      }
      .commentsList .commentDonation:nth-child(4n):before {
        background-image: url($assets-path + 'daisyA.png');
      }
    }
  }


  // animation for stars and christmas
  @keyframes glitter {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    50% {
      transform: scale(0.8);
      opacity: 0.6;
    }

    100% {
      transform: scale(1);
      opacity: 0.9;
    }
  }

  // animation for flowers
  @keyframes boogie {
    0% {
      transform: rotate(-5deg);
      transform-origin: 50% 100%;
    }

    25% {
      transform: rotate(0deg);
      transform-origin: 50% 100%;
    }

    50% {
      transform: rotate(5deg);
      transform-origin: 50% 100%;
    }

    75% {
      transform: rotate(0deg);
      transform-origin: 50% 100%;
    }

    100% {
      transform: rotate(-5deg);
      transform-origin: 50% 100%;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  body.#{$body-class},
  body.donate.#{$body-class} {
    background-color: $background-colour;
    #bodyForm {
      background-color: transparent;
      background-image: url($assets-path + $background-image);
      background-repeat: repeat-x;
    }
    .donationControls {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
    #donateCount {
      margin-bottom: 0;
      color: $comment-text-colour;
      &.loading {
        &:after {
          display: inline-block;
          content: '\f110';
          font-family: 'FontAwesome';
          animation: spin 2s linear infinite;
        }
      }
    }
  }

  body.donate.#{$body-class} #bodyForm {
    background-position: top center;
  }

  body.#{$body-class} {

    #bodyForm {
      color: $post-text-colour;
    }

    .appealFooterWrapper, .tabsWrapper {
      display: none;
    }
    
    .appealActionsWrapper {
      background-color: transparent;
      position: relative;
      padding: 0;
    }

    .appealActions {
      float: left;
      margin: 0;

      .fundraiseBtn {
        display: none;
      }
    }

    .headerText {
      position: relative;
      max-width: $container-max-width;
      margin: 0 auto;

      > * {
        padding: 0 $site-bleed;
        margin-top: 0;
        margin-bottom: 1.5rem;
        color: $post-text-colour;
      }

      &:before {
        content: '';
        position: absolute;
        width: 100vw;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: $background-colour-top;
        z-index: -1;
      }
    }

    .postContent {
      width: 100%;
      padding-right: 0;
    }

    .postAside {
      display: none;
    }

    .shareWrapper {
      display: none;
    }

    .appealTotaliser.totaliserPanel {
      display: none;
    }

    .appealInteractions {
      margin: 0;
      max-width: 100%;
      padding: 0;

      .tabsWrapper {
        .tabs {
          border: none;
        }
      }
    }

    .appealComments {
      max-width: $container-max-width;
      border: 0;
      margin: 0 auto;
      padding: 0;

      .postComments {
        border-top: none;
      }
    }

    .commentsListWrapper {
      margin: 0;
    }

    .commentsList {
      padding: 0;
      position: relative;
    }

    .commentsList {
      border-top: none;

      .commentDonation {
        position: absolute; // relative to the .appealInteractionsWrapper
        top: 0; // Values altered with JS
        left: 0;
        border: 0;
        padding: 0;
        margin: 0;
        display: inline-block;
        opacity: 0;
        transition: opacity 4s;

        .commentProfilePic {
          display: none;
        }

        &.commentDonation--positioned {
          opacity: 1;
        }

        // This is the icon for each comment on the field
        &:before {
          content: '';
          display: block;
          background-image: url($assets-path + $icon-image);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          width: $icon-size;
          height: $icon-size;
          margin-left: ($icon-size / 2) * -1;
          animation: $icon-animation;
          animation: $icon-animation;
          -moz-animation: $icon-animation;
          -ms-animation: $icon-animation;
          -o-animation: $icon-animation;
        }

        // Delay each icon a bit differently so they move not all in sync
        &:nth-child(2n) {
          &:before {
            animation-delay: .5s;
          }
        }

        &:nth-child(3n) {
          &:before {
            animation-delay: 1s;
          }
        }

        &:nth-child(4n) {
          &:before {
            animation-delay: 1.5s;
          }
        }

        &:nth-child(5n) {
          &:before {
            animation-delay: 2s;
          }
        }

        &:nth-child(6n) {
          &:before {
            animation-delay: 2.5s;
          }
        }

        &:nth-child(7n) {
          &:before {
            animation-delay: 3s;
          }
        }

        &:nth-child(8n) {
          &:before {
            animation-delay: 3.5s;
          }
        }

        // Reveal the comment when you hover on the icon
        &:hover {
          .commentContentWrapper {
            display: block;
          }
        }
      }
    }

    // Styles for the comment box
    .commentsList .commentContentWrapper {
      display: none;
      width: 300px !important;
      background: $appeal-content-colour;
      color: text-contrast($appeal-content-colour);
      border-radius: 5px;
      text-align: center;
      padding: 20px;
      position: absolute;
      transform: translateX(-50%);
      z-index: 2;

      // This is the little upward triangle above the comment box
      &:before {
        content: '\f0d8';
        font-family: 'FontAwesome';
        position: absolute;
        top: -18px;
        margin-left: -6px;
        font-size: 20px;
        color: $appeal-content-colour;
      }

      @media (max-width: map-get($breakpoints, md)) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100% !important;
        margin: 0;
        transform: none;
        border-radius: 0;

        &:before {
          display: none;
        }
      }
    }

    @media (min-width: map-get($breakpoints, md) + 1px) and (max-width: map-get($breakpoints, lg) + 300px) {

      // Comments that are far left
      .commentsList .commentDonation--hard-left .commentContentWrapper {
        transform: translateX(-18px);

        &:before {
          left: 20px;
        }
      }

      // Comments that are far right
      .commentsList .commentDonation--hard-right .commentContentWrapper {
        transform: translateX(calc(-100% + 33px));

        &:before {
          right: 20px;
        }
      }
    }

    .commentsList .commentContent {
      background: transparent;
      padding: 0;

      p {
        margin: 0 0 10px;
      }
    }

    .commentsList .commentDetails {
      p.commenterWrapper {
        margin: 0;
        text-transform: none;
        font-weight: bold;
      }

      p.commentDate {
        display: none;
      }
    }

    .commentsList .commentDonationAmount {
      display: none;
    }

    // Comments are cloned into this alternative ul which is printed out below the icon field
    ul.commentsList--printed {
      display: flex;
      flex-direction: column;
      padding-left: 0;

      li.commentDonation {
        list-style-type: none;
        padding: 20px 0 0 0;
        margin: 20px 0 0 0;
        border-top: 1px solid rgba($comment-text-colour, 0.2);
        color: $comment-text-colour;

        img {
          display: none;
        }
      }

      .commentContentWrapper {
        width: 75%;
        float: left;
      }

      .commentDonationAmount {
        float: right;
        text-align: right;
        color: $comment-text-colour;
      }

      .commentContent {
        background-color: transparent;
        padding: 0;
      }

      footer.commentDetails {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;

        >p {
          margin: 0 20px 0 0;
        }
      }
    }

    .postCommentsAlt {
      text-align: center;
      
      // We actually want to hide this from the user
      // We're clicking this with JS if it exists and loading more stars automatically
      opacity: 0;
      visibility: hidden;
      height: 0;
    }

    .makeCommentAlt {
      display: none; // Hide the 'Add a comment' button beside load more
    }

    // Hide Comments and donations box on appeal post
    h3#makeComment,
    .commentForm {
      display: none;
    }
  }

  
  body.donate.#{$body-class} {
  .headerText h1,
    .listingIntro {
      color: $post-text-colour;
    }
    .contentBlockWrapper>section,
    .yourDonation,
    .paymentForm,
    .registerForm {
      background: white;
    }
  }
}
// This appears at the bottom of /my-details to show the user their fundraising appeals

// List of all user's fundraising appeals
.associated.associatedFundraising .associatedListing {
  display: flex;
  flex-wrap: wrap;

  li {
    border: 0;
    padding: 0;
    width: 100%;
    margin: 0 0 30px;

    @include min-width(md) {
      width: calc( (100% - 60px) / 3 );
      margin: 0 30px 30px 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    // Banner image of parent appeal
    > a {
      display: block;
      margin-bottom: 8px;
    }

    .banner {
      width: 100%;
    }

    // Post title
    > h3 {
      font-size: $font-size-h5;
      margin-bottom: .5em;
      display: flex;
      flex-wrap: wrap;
    }
    // Quick summary
    > p {
      display: block;
      margin: 0;
    }
  }

  // Unapproved label
  .notYetApproved > h3:after {
    content: 'Pending approval';
    background-color: $warning-colour--background;
    color: $warning-colour--on-background;
    display: inline-flex;
    font-size: 10px;
    padding: 5px 8px;
    margin-left: 5px;
    border-radius: 12px;
    text-transform: uppercase;
  }
}

// Message shown to users if they navigate to their fundraiser before it is approved
// Just need some max widths on here

body[class*="fundraiser-pending-approval-"] {
  .headerText {
    width: 100%;
    max-width: $post-content-max-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: $site-bleed;
    padding-right: $site-bleed;
  }
}
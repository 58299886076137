// This is the page title that appears at the top of
// posts, or at the top of listing pages etc (hence not "post" title)

h1.title {
  font-size: $page-title-font-size;
  font-family: $page-title-font-family;
  font-weight: $page-title-font-weight;
  color: $page-title-colour;
  text-transform: $page-title-text-transform;
  margin: $page-title-margin-top 0 $page-title-margin-bottom;
  text-align: $page-title-text-align;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: $page-title-md-font-size;
  }

  @media (max-width: map-get($breakpoints, sm)) {
    font-size: $page-title-sm-font-size;
  }
}

// This will put the page title OVER the carousel banner image (if there is one)
@if $page-title-over-banner-enabled == true {
  @media (min-width: $page-title-over-banner-breakpoint + 1px) {

    // Doesn't work really on blog posts if author details are enabled,
    // because blog author details are also in the .headerText
    // element, which we want to absolutely position
    $page-title-over-banner-selector: ".page-has-banner:not(.page-has-video-banner) .post:not(.formWrapper)";
    @if $blog-details-enabled == true {
      $page-title-over-banner-selector: ".page-has-banner:not(.page-has-video-banner):not(.blogsPostBody) .post:not(.formWrapper)";
    }

    #{$page-title-over-banner-selector} {
      .headerWrapper {
        position: relative;
      }

      .headerText {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .headerText .title {
        text-align: center;
        background-color: $page-title-over-banner-background-colour;
        color: $page-title-over-banner-colour;
        padding: 0.5rem 0.75rem;
        width: auto;
        max-width: 600px;
        margin: 0 auto;
      }

      // Reintroduce the margin off of the carousel as headerText now absolute
      .carousel {
        margin-bottom: calc(
          #{$header-text-margin-top + $header-text-margin-bottom}
        );
      }

      @if $page-title-over-banner-breadcrumb-enabled == false {
        .breadcrumbWrapper {
          display: none;
        }
      } @else if $breadcrumb-enabled == true {
        .breadcrumbWrapper {
          width: auto;
          padding: 0.5rem 0.75rem;
          background-color: $white;
          border-radius: $border-radius;
          margin: -50px
            auto
            0.5em; // Negative top margin to try and hack the actual TITLE into the middle of the carousel frame, which is what I think we want here
        }

        .breadcrumbList {
          margin: 0;
        }

        .breadcrumbItem {
          margin: 0 0.25em;
        }
      }
    }
  }
}

//
// Keyframes
// These get output to CSS even if never used, so conditionally load these if as required
//

@if ($home-features-cards-entry-animation-prefab == 1) or
  ($home-feeds-cards-entry-animation-prefab == 1)
{
  @keyframes card-fly-in-bottom {
    to {
      transform: translateY(0);
    }
  }
}

@if ($home-features-cards-entry-animation-prefab == 1 or 2) or
  ($home-feeds-cards-entry-animation-prefab == 1 or 2)
{
  @keyframes card-fade-in {
    to {
      opacity: 1;
    }
  }
}

@if ($home-features-cards-entry-animation-prefab == 2) or
  ($home-feeds-cards-entry-animation-prefab == 2)
{
  @keyframes card-scale-in {
    to {
      transform: scale(1);
    }
  }
}

//
// Master mixins
//

@mixin entry-animation {
  &.in-view:not(.already-in-view) {
    animation-duration: $transition-duration-slow;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

@mixin entry-animation-stagger($number-of-cards) {
  @for $i from 2 through $number-of-cards {
    &:nth-child(#{$i}) {
      $ms: $i * 50;
      animation-delay: #{$ms}ms;
    }
  }
}

//
// Prefab mixins
//

@mixin entry-animation-prefab-1 {
  @media (prefers-reduced-motion: no-preference) {
    opacity: 0;
    transform: translateY(50%);

    &.in-view {
      animation-name: card-fly-in-bottom, card-fade-in;
    }
  }
}

@mixin entry-animation-prefab-2 {
  @media (prefers-reduced-motion: no-preference) {
    opacity: 0;
    transform: scale(0);

    &.in-view {
      animation-name: card-scale-in, card-fade-in;
    }
  }
}

//
// Home feature cards
//

@if $home-features-cards-entry-animation-prefab != 0 {
  .homeFeature:not(.homeFeatureSnippets) {
    @include entry-animation;

    @if $home-features-cards-entry-animation-stagger == true {
      @include entry-animation-stagger(
        $home-features-cards-entry-animation-stagger-count
      );
    }

    @if $home-features-cards-entry-animation-prefab == 1 {
      @include entry-animation-prefab-1;
    }

    @if $home-features-cards-entry-animation-prefab == 2 {
      @include entry-animation-prefab-2;
    }
  }
}

// Home feeds cards

@if $home-feeds-cards-entry-animation-prefab != 0 {
  .feedItem {
    @include entry-animation;
    @if $home-feeds-cards-entry-animation-stagger == true {
      @include entry-animation-stagger(
        $home-feeds-cards-entry-animation-stagger-count
      );
    }

    @if $home-feeds-cards-entry-animation-prefab == 1 {
      @include entry-animation-prefab-1;
    }

    @if $home-feeds-cards-entry-animation-prefab == 2 {
      @include entry-animation-prefab-2;
    }
  }
}

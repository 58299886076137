@if ($keeping-in-touch-enabled == false) or
  (
    ($keeping-in-touch-via-email-enabled == false) and
      ($keeping-in-touch-via-telephone-enabled == false) and
      ($keeping-in-touch-via-sms-enabled == false) and
      ($keeping-in-touch-via-post-enabled == false)
  )
{
  .formQuestion.labelCheckBoxPair.contactYou {
    display: none;
  }
}

// Checkboxes

.dataProtection .labelCheckBoxPair {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 0.5rem;

  label {
    flex-grow: 1;
    margin: 0 0 0 0.5rem;
  }

  @if $keeping-in-touch-via-email-enabled == false {
    &.contactViaEmail {
      display: none;
    }
  }

  @if $keeping-in-touch-via-telephone-enabled == false {
    &.contactViaTel {
      display: none;
    }
  }

  @if $keeping-in-touch-via-sms-enabled == false {
    &.contactViaSMS {
      display: none;
    }
  }

  @if $keeping-in-touch-via-post-enabled == false {
    &.contactViaPost {
      display: none;
    }
  }
}

// These are the prefabs for the overall FEEDS layout
@mixin home-feeds-prefab (
  $number-of-feeds,
  $prefab
) {

  // 2 FEEDS LAYOUTS
  @if $number-of-feeds == 2 {

    // ROWs LAYOUT
    @if $prefab == 1  {
      .homeFeed {
        width: 100%;
        margin: 0 auto;
        padding-top: $home-feeds-padding-top;
        padding-bottom: $home-feeds-padding-bottom;
      }

      .feedsTitle,
      .feedList {
        width: 100%;
        max-width: $home-feeds-max-width;
        margin-left: auto;
        margin-right: auto;
      }

      @include max-width(lg) {
        .homeFeed {
          padding-top: $home-feeds-padding-top * .5;
          padding-bottom: $home-feeds-padding-bottom * .5;
        }
      }
    }

    // COLUMNS LAYOUTS
    @if $prefab >= 2 {
      .homeFeedsWrapper {
        padding-top: $home-feeds-padding-top;
        padding-bottom: $home-feeds-padding-bottom;
      }

      .homeFeeds {
        display: flex;
        max-width: $home-feeds-max-width;
        margin-left: auto;
        margin-right: auto;
      }

      @include max-width(md) {
        .homeFeed {
          margin-bottom: $gap-width;
        }
      }

      @include min-width(md) {
        .homeFeed {
          margin-right: $spacer * 2; // Slightly bigger gap

          &:last-child {
            margin-right: 0;
          }
        }

        // 50:50
        @if $prefab == 2 {
          .homeFeed {
            flex: 1;
          }
        }

        // ~ 75:25
        @if $prefab == 3 {
          .homeFeedBox1 {
            flex: 2;
          }

          .homeFeedBox2 {
            flex: 1;
            flex-shrink: 0;
          }
        }

        @if $prefab == 4 {
          .homeFeedBox1 {
            flex: 1;
            flex-shrink: 0;
          }

          .homeFeedBox2 {
            flex: 2;
          }
        }
      }

      @include max-width(lg) {
        .homeFeedsWrapper {
          padding-top: $home-feeds-padding-top * .5;
          padding-bottom: $home-feeds-padding-bottom * .5;
        }
      }
    }

    @include prefab-warn($prefab, 2);

  }

  // 3 FEEDS LAYOUTS
  @if $number-of-feeds == 3 {

    // ROWS LAYOUT
    @if $prefab == 1  {
      .homeFeed {
        width: 100%;
        margin: 0 auto;
        padding-top: $home-feeds-padding-top;
        padding-bottom: $home-feeds-padding-bottom;
      }

      .feedsTitle,
      .feedList {
        width: 100%;
        max-width: $home-feeds-max-width;
        margin-left: auto;
        margin-right: auto;
      }

      @include max-width(lg) {
        .homeFeed {
          padding-top: $home-feeds-padding-top * .5;
          padding-bottom: $home-feeds-padding-bottom * .5;
        }
      }
    }

    // COLUMNS LAYOUTS
    @if $prefab == 2 {
      .homeFeedsWrapper {
        padding-top: $home-feeds-padding-top;
        padding-bottom: $home-feeds-padding-bottom;
      }

      .homeFeeds {
        display: flex;
        max-width: $home-feeds-max-width;
        margin-left: auto;
        margin-right: auto;
      }

      @include max-width(md) {
        .homeFeed {
          margin-bottom: $gap-width;
        }
      }

      @include min-width(lg) {
        .homeFeed {
          margin-right: $spacer * 1.2; // Slightly bigger gap
          flex: 1;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      @include min-width(md) {
        .homeFeeds {
          flex-wrap: wrap;
        }

        .homeFeed {
          &:first-child {
            margin-right: $spacer * 1.2;
          }

          &:nth-child(1),
          &:nth-child(2) {
            flex: 1;
          }

          &:nth-child(3) {
            width: 100%;
          }
        }
      }

      @include max-width(lg) {
        .homeFeedsWrapper {
          padding-top: $home-feeds-padding-top * .5;
          padding-bottom: $home-feeds-padding-bottom * .5;
        }
      }
    }

    @include prefab-warn($prefab, 2);
  }

}

// Icons to differentiate post types in mixed module feeds
@mixin home-feed-post-icons (
  $appeal-icon: '\f2c9',
  $appeal-colour: $brand-primary,
  $event-icon: '\f073',
  $event-colour: $brand-primary,
  $news-icon: '\f1ea',
  $news-colour: $brand-primary,
  $blog-icon: '\f086',
  $blog-colour: $brand-primary,
  $page-icon: '\f15c',
  $page-colour: $brand-primary,
  $faq-icon: '\f128',
  $faq-colour: $brand-primary,
  $icon-position-x: 'right', // 'right' or 'left'
  $icon-position-y: 'top', // 'top' or 'bottom'. 'bottom' won't work well if cards are card-text-overlay.
  $icon-font-size: $social-icons-font-size,
  $icon-width: $social-icons-width,
  $icon-height: $social-icons-height
) {
  .feedItem {
    .home-feed-image-link {
      position: relative;
    }
    .home-feed-image-link:after {
      position: absolute;
      width: $icon-width;
      height: $icon-height;
      font-family: $icon-font-family;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $icon-font-size;
      border-radius: 100%;
      z-index: 1;
      
      @if $icon-position-y == 'top' {
        top: 10px;
      }
      @if $icon-position-y == 'bottom' {
        bottom: 10px;
      }
      @if $icon-position-x == 'left' {
        left: 10px;
      }
      @if $icon-position-x == 'right' {
        right: 10px;
      }
    }

    &[onclick*='/appeal/'] {
      .home-feed-image-link:after {
        content: $appeal-icon;
        background-color: $appeal-colour;
        color: text-contrast($appeal-colour);
      }
  
      [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
        background-color: rgba($appeal-colour, 0.75);
        * {
          color: text-contrast($appeal-colour);
        }
      }
    }

    &[onclick*='/events/'] {
      .home-feed-image-link:after {
        content: $event-icon;
        background-color: $event-colour;
        color: text-contrast($event-colour);
      }
  
      [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
        background-color: rgba($event-colour, 0.75);
        * {
          color: text-contrast($event-colour);
        }
      }
    }

    &[onclick*='/news/'] {
      .home-feed-image-link:after {
        content: $news-icon;
        background-color: $news-colour;
        color: text-contrast($news-colour);
      }
  
      [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
        background-color: rgba($news-colour, 0.75);
        * {
          color: text-contrast($news-colour);
        }
      }
    }

    &[onclick*='/blog/'] {
      .home-feed-image-link:after {
        content: $blog-icon;
        background-color: $blog-colour;
        color: text-contrast($blog-colour);
      }
  
      [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
        background-color: rgba($blog-colour, 0.75);
        * {
          color: text-contrast($blog-colour);
        }
      }
    }

    // Long, silly selector for pages because the URL does not contain '/page/'
    &:not([onclick*='/appeal/']):not([onclick*='/events/']):not([onclick*='/news/']):not([onclick*='/blog/']):not([onclick*='/faqs/']) {
      .home-feed-image-link:after {
        content: $page-icon;
        background-color: $page-colour;
        color: text-contrast($page-colour);
      }
  
      [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
        background-color: rgba($page-colour, 0.75);
        * {
          color: text-contrast($page-colour);
        }
      }
    }
    
    &[onclick*='/faqs/'] {
      .home-feed-image-link:after {
        content: $faq-icon;
        background-color: $faq-colour;
        color: text-contrast($faq-colour);
      }
  
      [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
        background-color: rgba($faq-colour, 0.75);
        * {
          color: text-contrast($faq-colour);
        }
      }
    }
    
  }
}
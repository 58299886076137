.listing {
  @include if-required(
    background-color,
    transparent,
    $listing-body-background-colour
  );

  .contentBlockWrapper,
  .headerText > *:not(.subsiteTitle) {
    width: 100%;
    padding-left: $site-bleed;
    padding-right: $site-bleed;
    margin-left: auto;
    margin-right: auto;
  }

  // I don't think we ever really want the breadcrumb here to not be full width of the .headerText
  .breadcrumbWrapper {
    max-width: 100%;
  }

  .headerText {
    margin-left: auto;
    margin-right: auto;
  }

  .contentBlockWrapper {
    max-width: $listing-content-max-width;
  }

  .headerText {
    max-width: $listing-intro-max-width;
  }

  // Turn sidebar off on all listing pages
  // TODO: This could still be a viable option
  .postAside {
    display: none;
  }
}

.contentBlock:not(.fundraiserSearchResults) .listContent,
#curProjs,
#comProjs,
.funArea,
.fundraiserSearchResults:not(.contentBlock) {
  display: flex;
  flex-wrap: wrap;
  margin-left: ($card-gap-width / 2) * -1;
  margin-right: ($card-gap-width / 2) * -1;
}

.listedPost:not(.listedProduct) {
  @include card-row($listing-cards-per-row-desktop);

  @include max-width(lg) {
    @include card-row($listing-cards-per-row-tablet);
  }

  @include max-width(md) {
    width: 100%;
  }
}

@if $listed-post-read-more-enabled == false {
  .listedPost .readMore {
    display: none;
  }
}

.listFooter {
  grid-column: 1 / -1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: $spacer auto;

  .button {
    margin: $spacer / 2;
  }

  .loadAmount {
    width: 100%;
    text-align: center;
    font-size: $font-size-small;
    margin-top: $spacer / 2;
  }
}

//
// Listed post footer
//

// News
@if $listed-news-post-publish-date-enabled == true {
  .newsListingPage .listedPost footer {
    display: block;

    .publishDatesWrapper {
      display: block;
    }
  }
}

// Blog
.blogListingPage .listedPost footer .blogDetails {
  display: none; // We never want this
}

@if ($listed-blog-post-publish-date-enabled == true) or
  $listed-blog-post-author-enabled ==
  true
{
  .blogListingPage .listedPost footer {
    display: block;
  }
}

@if $listed-blog-post-publish-date-enabled == true {
  .blogListingPage .listedPost footer .publishDatesWrapper {
    display: block;
  }
}

@if $listed-blog-post-author-enabled == true {
  .blogListingPage .listedPost footer .publishAuthorWrapper {
    display: block;
  }
}

// Event
@if ($listed-event-post-start-date-enabled == true) or
  ($listed-event-post-location-enabled == true)
{
  section[onclick^="window.location='/events/"] footer,
  section[onclick^="window.location = '/Events/"] footer {
    display: block !important;
  }
}

@if $listed-event-post-start-date-enabled == true {
  section[onclick^="window.location='/events/"] footer .associatedStartDate,
  section[onclick^="window.location = '/Events/"] footer .associatedStartDate {
    display: block !important;
  }
}

@if $listed-event-post-location-enabled == true {
  section[onclick^="window.location='/events/"] footer .locationDetails,
  section[onclick^="window.location = '/Events/"] footer .locationDetails {
    display: block !important;
  }
}

// Floating event start date
@if $event-card-floating-date {
  .listedPost.listedEvent,
  .homeFeedevents .feedItem {
    position: relative;

    .associatedStartDate {
      position: absolute;
      margin: $event-card-floating-date-margin-y
        $event-card-floating-date-margin-x !important;
      top: 0;
      left: 0;
      opacity: 1 !important;
      background-color: $event-card-floating-date-background-colour;
      padding: $btn-padding-y $btn-padding-x;
      color: $event-card-floating-date-colour;
      border-radius: $event-card-floating-date-border-radius;
    }
  }

  // If we JUST have the start date (i.e. no location) then we need to remove the padding from the effectively empty footer element at the bottom of the card
  @if ($listed-event-post-start-date-enabled == true) and
    ($listed-event-post-location-enabled == false)
  {
    .listedPost.listedEvent footer {
      padding: 0 !important;
    }
  }

  // Same for feed items
  @if ($feed-item-event-start-date-enabled == true) and
    ($feed-item-event-location-enabled == false)
  {
    .homeFeedevents .feedItem footer {
      padding: 0 !important;
    }
  }
}

// Appeal
@if $listed-appeal-totaliser-enabled == true {
  .listedPost .appealFooterWrapper {
    display: block !important;
    padding: 0 !important;

    // Never want this
    .topFundraisersWrapper {
      display: none;
    }

    .totaliserPanel {
      margin: 0.5rem $card-details-padding;
      text-align: left;
      position: relative;
      display: grid;
      grid-template-columns: 65px 1fr;
      grid-template-rows: auto auto;
      grid-column-gap: 0.5rem;
    }

    .totaliserBG {
      transform: none;
      position: relative;
      top: auto;
      left: auto;
      width: 65px;
      height: 65px;
      border-radius: 65px;
      background-color: $white;
      grid-column: 1 / 2;
      grid-row: 1 / 3;
      margin: 0;
    }

    @keyframes totaliser-listed-post-done-fill {
      0% {
        transform: translateY(100%);
      }

      100% {
        transform: translateY(0);
      }
    }

    .totaliserDone {
      position: absolute;
      bottom: 0;
      width: 100%;
      animation: totaliser-listed-post-done-fill 3s $transition-curve;
    }

    .targetWrapper,
    .raisedWrapper {
      grid-column: 2 / 3;
      width: 100%;
      margin: 0;
      align-items: flex-start;
    }

    .raisedWrapper {
      grid-row: 1 / 2;
    }

    .raisedAmount {
      font-size: 1.8rem;
      line-height: 1;
    }

    .targetWrapper {
      grid-row: 2 / 3;
      display: block;
    }
  }
}

// In the sidebar of appeal posts there's a widget which
// invites users to set either find a fundraiser, or set up
// their own fundraising page

@if $fundraising-enabled == false or $find-a-fundraiser-widget-enabled == false {
  .findAFundraiserWidget {
    display: none;
  }
} @else {
  .findAFundraiserWidget {
    margin-bottom: $gap-width;

    h3 {
      font-size: $font-size-h5;
      margin-bottom: 15px;
    }
  }

  // Grid of fundraiser profile photos
  @if $find-a-fundraiser-profile-pics-enabled == false {
    .fundraiserProfilePics {
      display: none;
    }
  } @else {
    .fundraiserProfilePics {
      @include list-unstyled;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px 10px 0;

      li {
        margin: 0 5px 5px 0;
        width: 60px;
      }

      li a {
        display: block;

        img {
          display: block;
          width: 100%;
        }
      }
    }
  }

  // 'Search fundraisers' element of find a fundraiser sidebar widget
  .findAFundraiserWidget .searchFundraisers {
    padding: 15px;
    background-color: $context-container-background-colour;
    border-radius: $context-container-border-radius;

    input {
      width: 100%;
      margin-bottom: 15px;
    }

    button {
      margin: 0;
    }
  }
}
// Information about the blog author that appears at the top of blog posts

@if $blog-details-enabled == false {
  .blogDetails {
    display: none;
  }
} @else {
  // Never show it at the top of a listing page
  .listing .blogDetails {
    display: none;
  }

  .blogDetails {
    @include context-container (
      $context-container-padding: $blog-details-padding,
      $context-container-border-radius: $blog-details-border-radius,
      $context-container-background-colour: $blog-details-background-colour
    );
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr auto;
    margin: $gap-width auto;
    font-size: $font-size-small;
    @include parentless-container-wrapper($post-content-max-width);

    > * {
      align-self: center;
    }

    @if $blog-details-profile-pic-enabled == false {
      display: flex;
      flex-direction: column;

      > * { 
        align-self: flex-start;
      }
    }

    // Profile pic
    .memberProfilePic {
      @if $blog-details-profile-pic-enabled == false {
        display: none;
      } @else {
        display: block;
        width: 80px;
        margin-right: $spacer;
        grid-column: 1 / 2;
        grid-row: 1 / 3;

        img {
          width: 100%;
        }
      }
    }

    .memberName {
      font-size: $font-size-h4;
    }

    .memberDescription {
      margin: 0;
    }
  }

  // Hide the "Posted to" bit at the bottom of a blog post
  .postFooterWrapper .blogDetails {
    display: none;
  }
}


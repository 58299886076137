.cc-bottom {
  @if $cookie-consent-position == top {
    bottom: auto !important;
    top: 0;
  } @else {
    bottom: 0 !important;
  }
}

.cc-window {
  background-color: $cookie-consent-background-colour !important;
  color: $cookie-consent-colour !important;

  @if ($cookie-consent-font-family != initial) {
    font-family: $cookie-consent-font-family !important;
  }
}

.cc-btn {
  border-radius: $btn-border-radius !important;
  background-color: $cookie-consent-btn-background-colour !important;
  color: $cookie-consent-btn-text-colour !important;

  &:hover {
    text-decoration: none;
    @include button-hover-state($cookie-consent-btn-background-colour);
  }
}
@mixin footerNewsletterSuggestion($footerBoxNumber) {
  @if $warn == true and $newsletter-enabled {
    @warn "Footer prefab works best with newsletter in footerBox#{$footerBoxNumber}";
  }
}

// Footer prefabs!
// We're using "ewsletter" as a snippet title match for newsletter
// to be case insensitive. Still requires the snippet title to have
// this string in it though, so clients can still easily break it

@mixin footer-prefab (
  $boxes-count,
  $prefab
) {

  // 1 box prefabs
  @if $boxes-count == 1 {
    // No prefabs
    @include newsletter-hero;
    .pageFooter {
      display: flex;
      justify-content: center;
    }
    .footerBox:not([class*="ewsletter"]) {
      margin: $footer-padding-y ($gap-width / 2) 0;
      @if $newsletter-enabled {
        display: none;
      }
    }
  }

  // 2 box prefabs
  @if $boxes-count == 2 {
    // 2-1 (stacked vertically)
    @if $prefab == 1 {
      @include newsletter-hero;

      .footerBox:not([class*="ewsletter"]) {
        margin: $footer-padding-y ($gap-width / 2) 0;
      }

      @if $newsletter-enabled {

        .footerBox2 {
          grid-column: 3 / span 12;
          margin: $footer-padding-y ($gap-width / 2) 0;
        }
  
        .footerBox2 {
          grid-row: 2 / 3;
        }  
      }

      @else {
        .footerBox1,
        .footerBox2 {
          grid-column: 3 / span 12;
          margin: $footer-padding-y ($gap-width / 2) 0;
        }

        .footerBox1 {
          grid-row: 2 / 3;
        }

        .footerBox2 {
          grid-row: 3 / 4;
        }
      }
    }

    // 2-2 (horizontal)
    @if $prefab == 2 {
      @include newsletter-split;
      @include footerNewsletterSuggestion(1);

      .footerBox:not([class*="ewsletter"]) {
        margin: $footer-padding-y ($gap-width / 2) 0;
      }

      @if $newsletter-enabled {
        .footerBox {
          margin: 0 ($gap-width / 2);
        }
  
        .footerBox1{
          grid-column: 3 / span 6;
        }
  
        .footerBox2 {
          grid-column: 9 / span 6;
        }
  
        @include max-width(md) {
          .footerBox1 {
            grid-column: 3 / span 12;
          }
  
          .footerBox2 {
            grid-column: 3 / span 6;
            grid-row: 2 / 3;
            margin-top: $gap-width;
          }
        }
      }

      @else {
        .footerBox1 {
          grid-column: 3 / span 6;
        }
  
        .footerBox2 {
          grid-column: 9 / span 6;
        }
  
        @include max-width(md) {
          .footerBox1 {
            grid-column: 3 / span 12;
          }
  
          .footerBox2 {
            grid-column: 3 / span 12;
            grid-row: 2 / 3;
            margin-top: $gap-width;
          }
        }
      }

    }
  }

  // 3 box prefabs
  @if $boxes-count == 3 {

    // 3-1
    @if $prefab == 1 {
      @include newsletter-hero;

      .footerBox:not([class*="ewsletter"]) {
        margin: $footer-padding-y ($gap-width / 2) 0;
      }

      .footerBox2 {
        grid-column: 3 / span 6;
        grid-row: 2 / 3;
      }

      .footerBox3 {
        grid-column: 9 / span 6;
        grid-row: 2 / 3;
      }
    }

    // 3-2
    @if $prefab == 2 {
      @include newsletter-hero;

      .footerBox:not([class*="ewsletter"]) {
        margin: $footer-padding-y ($gap-width / 2) 0;
      }

      .footerBox2 {
        grid-column: 3 / span 4;
        grid-row: 2 / 3;
      }

      .footerBox3 {
        grid-column: 7 / span 8;
        grid-row: 2 / 3;
      }
    }

    // 3-3
    @if $prefab == 3 {
      @include newsletter-hero;

      .footerBox:not([class*="ewsletter"]) {
        margin: $footer-padding-y ($gap-width / 2) 0;
      }

      .footerBox2 {
        grid-column: 3 / span 8;
        grid-row: 2 / 3;
      }

      .footerBox3 {
        grid-column: 11 / span 4;
        grid-row: 2 / 3;
      }
    }

    // 3-4
    @if $prefab == 4 {
      @include newsletter-hero;

      .footerBox2,
      .footerBox3 {
        grid-column: 3 / span 12;
        margin: $footer-padding-y ($gap-width / 2) 0;
      }

      .footerBox2 {
        grid-row: 2 / 3;
      }

      .footerBox3 {
        grid-row: 3 / 4;
      }
    }

    // 3-5
    @if $prefab == 5 {
      @include newsletter-split;
      @include footerNewsletterSuggestion(1);

      .footerBox {
        margin: 0 ($gap-width / 2);
      }

      .footerBox1{
        grid-column: 3 / span 6;
      }

      .footerBox2 {
        grid-column: 9 / span 3;
      }

      .footerBox3 {
        grid-column: 12 / span 3;
      }

      @include max-width(md) {
        .footerBox1 {
          grid-column: 3 / span 12;
        }

        .footerBox2 {
          grid-column: 3 / span 6;
          grid-row: 2 / 3;
          margin-top: $gap-width;
        }

        .footerBox3 {
          grid-column: 9 / span 6;
          grid-row: 2 / 3;
          margin-top: $gap-width;
        }
      }
    }

    // 3-6
    @if $prefab == 6 {
      @include newsletter-split;
      @include footerNewsletterSuggestion(3);

      .footerBox {
        margin: 0 ($gap-width / 2);
      }

      .footerBox1{
        grid-column: 3 / span 3;
      }

      .footerBox2 {
        grid-column: 6 / span 3;
      }

      .footerBox3 {
        grid-column: 9 / span 6;
      }

      @include max-width(md) {
        .footerBox1 {
          grid-column: 3 / span 12;
        }

        .footerBox2 {
          grid-column: 3 / span 6;
          grid-row: 2 / 3;
          margin-top: $gap-width;
        }

        .footerBox3 {
          grid-column: 9 / span 6;
          grid-row: 2 / 3;
          margin-top: $gap-width;
        }
      }
    }

    // 3-7
    @if $prefab == 7 {
      @include newsletter-column;

      .footerBox {
        margin: 0 ($gap-width / 2);
      }

      .footerBox1 {
        grid-column: 3 / span 4;
      }

      .footerBox2 {
        grid-column: 7 / span 4;
      }

      .footerBox3 {
        grid-column: 11 / span 4;
      }

      @include max-width(md) {
        .footerBox1 {
          grid-column: 3 / span 12;
        }

        .footerBox2,
        .footerBox3 {
          grid-row: 2 / 3;
        }

        .footerBox2 {
          grid-column: 3 / span 6;
          margin-top: $gap-width;
        }

        .footerBox3 {
          grid-column: 9 / span 6;
          margin-top: $gap-width;
        }
      }
    }

    @include prefab-warn($prefab, 7);

    // Set single col universally on mobile
    @include max-width(sm) {
      .footerBox2, .footerBox3 {
        grid-column: 3 / span 12;
      }

      .footerBox2 {
        grid-row: 2 / 3;
      }

      .footerBox3 {
        grid-row: 3 / 4;
      }
    }

  }

  // 4 box prefabs
  @if $boxes-count == 4 {

    // 4-1
    @if $prefab == 1 {
      @include newsletter-hero;

      .footerBox:not([class*="ewsletter"]) {
        margin: $footer-padding-y ($gap-width / 2) 0;
        grid-row: 2 / 3;
        // grid-column: span 4; // IE doesn't like this, need to be more specific
      }

      .footerBox2 {
        grid-column: 3 / span 4;
      }

      .footerBox3 {
        grid-column: 7 / span 4;
      }

      .footerBox4 {
        grid-column: 11 / span 4;
      }

      @include max-width(md) {
        .footerBox2 {
          grid-column: 3 / span 6;
        }

        .footerBox3 {
          grid-column: 9 / span 6;
        }

        .footerBox.footerBox4 {
          grid-row: 3 / 4;
          grid-column: 3 / span 12;
          margin-top: $gap-width;
        }
      }
    }

    // 4-2
    @if $prefab == 2 {
      @include newsletter-hero;

      .footerBox:not([class*="ewsletter"]) {
        margin: $footer-padding-y ($gap-width / 2) 0;
      }

      .footerBox2 {
        grid-column: 3 / span 6;
        grid-row: 2 / 3;
      }

      .footerBox3 {
        grid-column: 9 / span 6;
        grid-row: 2 / 3;
      }

      .footerBox.footerBox4 {
        grid-column: 3 / span 12;
        grid-row: 3 / 4;
        margin-top: $gap-width;
      }
    }

    // 4-3
    @if $prefab == 3 {
      @include newsletter-column;

      .footerBox {
        margin: 0 $gap-width / 2;
      }

      .footerBox1 {
        grid-column: 3 / span 3;
      }

      .footerBox2 {
        grid-column: 6 / span 3;
      }

      .footerBox3 {
        grid-column: 9 / span 3;
      }

      .footerBox4 {
        grid-column: 12 / span 3;
      }

      @include max-width(lg) {
        .footerBox1,
        .footerBox3 {
          grid-column: 3 / span 6;
        }

        .footerBox2,
        .footerBox4 {
          grid-column: 9 / span 6;
        }

        .footerBox3,
        .footerBox4 {
          grid-row: 2 / 3;
          margin-top: $gap-width;
        }
      }

      @include max-width(sm) {
        .footerBox {
          grid-column: 3 / span 12 !important;
        }

        // Annoyingly, IE wants all these specified
        .footerBox1 {
          grid-row: 1 / 2;
        }

        .footerBox2 {
          grid-row: 2 / 3;
          margin-top: $gap-width;
        }

        .footerBox3 {
          grid-row: 4 / 5;
        }

        .footerBox4 {
          grid-row: 5 / 6;
        }
      }
    }

    // 4-4
    @if $prefab == 4 {
      @include newsletter-split;
      @include footerNewsletterSuggestion(3);

      .footerBox {
        margin: 0 $gap-width / 2;
      }

      .footerBox1 {
        grid-column: 3 / span 3;
      }

      .footerBox2 {
        grid-column: 6 / span 3;
      }

      .footerBox3 {
        grid-column: 9 / span 6;
      }

      .footerBox4 {
        grid-column: 3 / span 12;
        grid-row: 2 / 3;
        margin-top: $gap-width;
      }

      @include max-width(md) {
        .footerBox1 {
          grid-column: 3 / span 6;
        }

        .footerBox2 {
          grid-column: 9 / span 6;
        }

        .footerBox3 {
          grid-column: 3 / span 12;
          grid-row: 2 / 3;
          margin-top: $gap-width;
        }

        .footerBox4 {
          grid-row: 3 / 4;
        }
      }

      @include max-width(sm) {
        .footerBox {
          grid-column: 3 / span 12 !important;
        }

        // Annoyingly, IE wants all these specified
        .footerBox1 {
          grid-row: 1 / 2;
        }

        .footerBox2 {
          grid-row: 2 / 3;
          margin-top: $gap-width;
        }

        .footerBox3 {
          grid-row: 4 / 5;
        }

        .footerBox4 {
          grid-row: 5 / 6;
        }
      }
    }

    // 4-5
    @if $prefab == 5 {
      @include newsletter-split;
      @include footerNewsletterSuggestion(1);

      .footerBox {
        margin: 0 $gap-width / 2;
      }

      .footerBox1{
        grid-column: 3 / span 6;
      }

      .footerBox2 {
        grid-column: 9 / span 3;
      }

      .footerBox3 {
        grid-column: 12 / span 3;
      }

      .footerBox4 {
        grid-column: 3 / span 12;
        grid-row: 2 / 3;
        margin-top: $gap-width;
      }

      @include max-width(md) {
        .footerBox1 {
          grid-column: 3 / span 12;
        }

        .footerBox2 {
          grid-column: 3 / span 6;
          grid-row: 2 / 3;
          margin-top: $gap-width;
        }

        .footerBox3 {
          grid-column: 9 / span 6;
          grid-row: 2 / 3;
          margin-top: $gap-width;
        }

        .footerBox4 {
          grid-row: 3 / 4;
        }
      }
    }

    // 4-6
    @if $prefab == 6 {
      @include newsletter-hero;

      .footerBox:not([class*="ewsletter"]) {
        margin: $gap-width ($gap-width / 2);

        &:not(.footerBox2) {
          margin-top: 0;
        }
      }

      .footerBox2, .footerBox3, .footerBox4 {
        grid-column: 3 / span 12;
      }

      .footerBox2 {
        grid-row: 2 / 3;
      }

      .footerBox3 {
        grid-row: 3 / 4;
      }

      .footerBox4 {
        grid-row: 4 / 5;
      }
    }

    @include prefab-warn($prefab, 6);

    // Set single col universally on mobile
    @include max-width(sm) {
      .footerBox2, .footerBox3, .footerBox4 {
        grid-column: 3 / span 12;
      }

      .footerBox.footerBox3 {
        grid-row: 3 / 4;
        margin-top: $gap-width;
      }

      .footerBox.footerBox4 {
        grid-row: 4 / 5;
      }
    }
  }

  // 5 box prefabs
  @if $boxes-count == 5 {
    // 5-1
    @if $prefab == 1 {
      @include newsletter-hero;

      .footerBox:not([class*="ewsletter"]) {
        margin: $footer-padding-y ($gap-width / 2) 0;
      }

      .footerBox2 {
        grid-column: 3 / span 4;
        grid-row: 2 / 3;
      }

      .footerBox3 {
        grid-column: 7 / span 4;
        grid-row: 2 / 3;
      }

      .footerBox4 {
        grid-column: 11 / span 4;
        grid-row: 2 / 3;
      }

      .footerBox5 {
        grid-column: 3 / span 12;
        grid-row: 3 / 4;
      }
    }

    // 5-2
    @if $prefab == 2 {
      @include newsletter-hero;

      .footerBox:not([class*="ewsletter"]) {
        margin-left: $gap-width / 2;
        margin-right: $gap-width / 2;
      }

      .footerBox2 {
        grid-column: 3 / span 6;
        grid-row: 2 / 3;
        margin-top: $footer-padding-y;
      }

      .footerBox3 {
        grid-column: 9 / span 6;
        grid-row: 2 / 3;
        margin-top: $footer-padding-y;
      }

      .footerBox4 {
        grid-column: 3 / span 6;
        grid-row: 3 / 4;
        margin-top: $gap-width;
      }

      .footerBox5 {
        grid-column: 9 / span 6;
        grid-row: 3 / 4;
        margin-top: $gap-width;
      }
    }

    // 5-3
    @if $prefab == 3 {
      @include newsletter-hero;

      .footerBox:not([class*="ewsletter"]) {
        margin-left: $gap-width / 2;
        margin-right: $gap-width / 2;
      }

      .footerBox2 {
        grid-column: 3 / span 12;
        grid-row: 2 / 3;
        margin-top: $footer-padding-y;
      }

      .footerBox3 {
        grid-column: 3 / span 4;
        grid-row: 3 / 4;
        margin-top: $gap-width;
      }

      .footerBox4 {
        grid-column: 7 / span 4;
        grid-row: 3 / 4;
        margin-top: $gap-width;
      }

      .footerBox5 {
        grid-column: 11 / span 4;
        grid-row: 3 / 4;
        margin-top: $gap-width;
      }
    }

    // 5-4
    @if $prefab == 4 {
      @include newsletter-column;

      .footerBox {
        margin: $gap-width / 2;
      }

      .footerBox1 {
        grid-column: 3 / span 3;
        grid-row: 1 / 2;
      }

      .footerBox2 {
        grid-column: 6 / span 3;
        grid-row: 1 / 2;
      }

      .footerBox3 {
        grid-column: 9 / span 3;
        grid-row: 1 / 2;
      }

      .footerBox4 {
        grid-column: 12 / span 3;
        grid-row: 1 / 2;
      }

      .footerBox5 {
        grid-column: 3 / span 12;
        grid-row: 2 / 3;
        margin-bottom: 0;
      }
    }

    @include prefab-warn($prefab, 4);

    // Set single col universally on mobile
    @include max-width(md) {
      .footerBox1, .footerBox2, .footerBox3, .footerBox4, .footerBox5 {
        grid-column: 3 / span 12;
      }

      .footerBox2 {
        grid-row: 2 / 3;
      }

      .footerBox3 {
        grid-row: 3 / 4;
        margin-top: $gap-width !important; // This is some song and dance
      }

      .footerBox4 {
        grid-row: 4 / 5;
        margin-top: $gap-width !important;
      }

      .footerBox5 {
        grid-row: 5 / 6;
        margin-top: $gap-width !important;
      }
    }
  }

}

// Invoke the prefab

@include footer-prefab(
  $boxes-count: $footer-boxes-count,
  $prefab: $footer-prefab
);
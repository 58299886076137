// On /my-details (and perhaps elsewhere) there's an opportunity to upload a profile picture
// for your user account. This styles that to make it less janky.

#uploadedImage {
  max-width: 100px; // The uploaded image gets resized to 100px square anyway. So just limit the 1000px(!) placeholder thumbnail we're loading
}

.uploadedImage {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: $gap-width;

  .buttonIcon.remove {
    position: absolute;
    top: -10px;
    right: -10px;
  }
}
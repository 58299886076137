.homeIntroWrapper {
  position: relative;
  width: 100%;
  max-width: $home-intro-max-width;
  margin-top: $home-intro-margin-top;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $home-intro-margin-bottom;
  padding: $home-intro-padding-y 0;
  text-align: $home-intro-text-align;
  text-transform: $home-intro-text-transform;
  font-family: $home-intro-font-family;
  font-size: $home-intro-font-size;
  color: $home-intro-colour;
  border-radius: $home-intro-border-radius;
  font-weight: $home-intro-font-weight;
  line-height: $home-intro-line-height;
  box-shadow: $home-intro-box-shadow;
  @include parentless-container-wrapper($home-intro-max-width);
  display: flex;

  // Solid background colour
  @if $home-intro-background-gradient == none {
    background-color: $home-intro-background-colour;
  }

  // Gradient
  @else {
    background: $home-intro-background-gradient;
  }


  @if $home-intro-content-align == center {
    justify-content: center;
  }

  @if $home-intro-content-align == right {
    justify-content: flex-end;
  }

  // Background image
  @if $home-intro-background-image != null {
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      background-image: url($assets-path + $home-intro-background-image);
      z-index: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: $home-intro-background-image-opacity;
      z-index: 0;
    }
  }
}

.homeIntro {
  position: relative;
  padding: 0 $site-bleed;
  width: 100%;
  max-width: $home-intro-content-max-width;
  line-height: inherit;

  * {
    font-family: inherit;
  }

  > * {
    line-height: inherit;
    text-transform: inherit;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  a:not(.button) {
    color: inherit;
    text-decoration: underline;
  }
}


//
// Responsive
//

@include max-width(lg) {
  .homeIntroWrapper {
    margin-top: $home-intro-margin-top * .5;
    margin-bottom: $home-intro-margin-bottom * .5;
  }
}

@include max-width(md) {
  .homeIntroWrapper {
    padding: $home-intro-mobile-padding-y 0;
    font-size: $home-intro-mobile-font-size;
  }
}
// Clients can insert a Google map to plot posts by category using the TinyMCE editor

.postContent,
.listingIntro {
  div[id^="googleMap"] {
    width: 100% !important;
    margin-bottom: $gap-width;
  }
}

.googleSearchWrapper {
  @include context-container;
  display: flex;
  align-items: center;

  .postcodeSearch {
    flex-grow: 1;
    margin-right: .5em;
  }

  .button {
    margin: 0;
  }
}

.mapResultList {
  margin-top: $gap-width;
  margin-bottom: $gap-width;

  &:before {
    content: "Results (in view)";
    display: block;
    width: 100%;
    margin: 0 ($card-gap-width / 2) .5em;
    border-bottom: 1px solid $border-colour;
    font-weight: $font-weight-bold;
  }

  .listedPost[class*="info"] {
    @include card();
    @include card-side-by-side(
      $card-side-by-side-image-width: 200px
    );
    margin-bottom: 0;
    width: 100%;

    h2 {
      margin-top: 0;
      font-size: $font-size-h4;
    }

    @include max-width(sm) {
      > * {
        width: 100%;
      }
    }
  }
}


//
// Responsive
//

@include max-width(sm) {
  .googleSearchWrapper {
    flex-direction: column;

    .postcodeSearch {
      width: 100%;
      margin: 0 0 .5em;
    }

    .button {
      width: 100%;
    }
  }
}
blockquote {
  display: block;
  position: relative;
  clear: both;
  margin: $blockquote-margin-y auto;
  width: 100%;
  max-width: $blockquote-max-width;
  border-radius: $blockquote-border-radius;
  padding: $blockquote-padding;
  text-align: $blockquote-text-align;
  font-size: $blockquote-font-size;
  font-family: $blockquote-font-family;
  font-weight: $blockquote-font-weight;
  line-height: $blockquote-line-height;
  color: $blockquote-colour;

  // Solid colour
  @if $blockquote-background-gradient == none {
    background-color: $blockquote-background-colour;
  }

  // Gradient
  @else {
    background: $blockquote-background-gradient;
  }

  // Check if either the blockquote max-width is a percentage, or bigger than the post-content container. If so, add our psuedo elements
  @if ($blockquote-max-width == 100%) or
    ($blockquote-max-width > $post-content-max-width)
  {
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      background-color: $blockquote-background-colour;
      height: 100%;
      top: 0;
    }

    &:before {
      right: 100%;
    }

    &:after {
      left: 100%;
    }
  }

  // If a pixel value and larger than post-content container, work out how wide the psuedo elements need to be
  @if (str-index($string: quote($blockquote-max-width), $substring: 'px')) and
    ($blockquote-max-width > $post-content-max-width)
  {
    $blockquote-flank-excess: (
        $blockquote-max-width - $post-content-max-width
      ) /
      2;

    &:before,
    &:after {
      width: $blockquote-flank-excess;
    }
  }

  // If blockquote max width is set to 100%, size psuedo elements accordingly
  @if ($blockquote-max-width == 100%) {
    &:before,
    &:after {
      width: 100%;
    }
  }

  @include if-required (box-shadow, none, $blockquote-box-shadow);

  > * {
    display: block;
    color: inherit;
    font-family: inherit;
    line-height: inherit;
    color: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: underline;
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  @include max-width(sm) {
    font-size: $blockquote-font-size * 0.75;
  }
}

// Remove excess blockquote max width behaviour on event and appeal
// posts where sidebar content is adjacent

@if $sidebar-layout == adjacent {
  .eventContent,
  .appealContent {
    blockquote {
      &:before,
      &:after {
        display: none;
      }
    }
  }
}

// Disable scroll if horizontal blockquote is set to full-screen
@if ($blockquote-max-width == 100%) {
  body {
    overflow-x: hidden;
  }
}
// On the /signup page (and potentially other flows), the client can add 'contact reasons' at /manager/settings/gdpr/
// This CSS styles these appropriately, otherwise they come out weird.

.registerForm .formQuestion.contactReasons {
  flex-direction: column;
  align-items: stretch;

  .contactReasonOption {
    flex-direction: row-reverse;
    justify-content: flex-end; // because reverse

    input {
      flex-grow: 0;
    }

    label {
      flex-grow: 1;
    }

    @include max-width(md) {
      input, label {
        width: auto;
      }

      label {
        margin: 0;
      }
    }
  }
}


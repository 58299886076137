// Gift aid statement
.donationGiftAid {
  display: block;
  margin: $gap-width 0;
  padding: $gap-width;
  padding-right: 200px;
  background-color: $context-container-background-colour;
  border-radius: $border-radius;
  background-image: url('/manager/Images/giftaid-logo60.png');
  background-repeat: no-repeat;
  background-position: top $gap-width right $gap-width;
  background-size: 140px auto;

  @include max-width(md) {
    padding-right: $gap-width;
    padding-top: $gap-width + 50px;
    background-position: top $gap-width left $gap-width;
    background-size: auto 40px;
  }

  // "If you are a UK taxpayer..."
  .checkboxRadio {
    display: block;
    font-size: $font-size-large;
    margin-bottom: $gap-width / 2;
  }

  // The value in the giftaid statement
  .donationGiftAidAmount {
    color: $donate-colour;
    font-weight: $font-weight-bold;
  }

  // The checkbox itself
  #giftAid {
    float: left; // To the left of the supporting statements
    margin-top: 5px; // Align it with the baseline of the supporting statements
    width: auto;
  }

  .giftAidStatement {
    font-size: $font-size-small;
    margin-left: 30px; // Clear the floated checkbox

    &:last-child {
      margin-bottom: 0;
    }
  }
}
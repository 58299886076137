@mixin home-feed(
  // Feeds title variables
    $feeds-title-font-family: $feeds-title-font-family,
  $feeds-title-font-size: $feeds-title-font-size,
  $feeds-title-colour: $feeds-title-colour,
  $feeds-title-text-align: $feeds-title-text-align,
  $feeds-title-text-transform: $feeds-title-text-transform,
  // Feed settings
    $home-feed-background-colour: $home-feeds-background-colour
) {
  background-color: $home-feed-background-colour;
  position: relative;

  .feedsTitle {
    font-family: $feeds-title-font-family;
    font-size: $feeds-title-font-size;
    color: $feeds-title-colour;
    padding-left: $card-gap-width / 2; // It's a sibling of a card so match the margin approach here
    padding-right: $card-gap-width / 2;
    margin-bottom: $feeds-title-margin-bottom;
    text-align: $feeds-title-text-align;
    text-transform: $feeds-title-text-transform;
  }
}

.homeFeedsWrapper {
  background-color: $home-feeds-background-colour;
}

.homeFeeds {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.homeFeed {
  @include home-feed;
}

.feedItem {
  @if $feed-item-read-more-enabled == false {
    .readMore {
      display: none;
    }
  }
}

.feedList {
  @include list-unstyled;
}

// Specific card footer settings for event feed items
// Using !important here to get around specificity issues we may
// run into when nesting the card() mixin within class selectors in _prefabs
@if ($feed-item-event-start-date-enabled == true) or
  ($feed-item-event-location-enabled == true)
{
  .homeFeedevents .feedItem footer {
    display: block !important;
  }
}

@if $feed-item-event-start-date-enabled == true {
  .homeFeedevents .feedItem footer .associatedStartDate {
    display: block !important;
  }
}

@if $feed-item-event-location-enabled == true {
  .homeFeedevents .feedItem footer .locationDetails {
    display: block !important;
  }
}
.searchListingIntroWrapper {
  width: 100%;
  max-width: $post-content-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $site-bleed;
  padding-right: $site-bleed;

  .searchContainer {
    @include context-container;

    .formQuestion label {
      margin-bottom: 5px;
    }
  }
}

.searchFilterWrapper {
  font-size: $font-size-small;
  display: flex !important;
}

.moduleFilterContent {
  flex-grow: 1;
  padding-right: $gap-width;

  h2 {
    font-size: $font-size-h6;
  }
}

.numberSearchResults {
  @include hint($success-colour--on-background, $success-colour--background);
  margin-bottom: $gap-width;
}

.searchResultsArea {
  display: flex;
  flex-direction: column;

  .listedPost {
    width: 100%;
    margin: 0;
    display: block;
    padding: 20px 0;
    border-bottom: $border-width solid $border-colour;

    &:first-of-type {
      border-top: $border-width solid $border-colour;
    }

    .listed-post-image-link {
      display: none;
    }

    p {
      margin: 0;
    }

    .postDetails {
      // Sometimes we get a blog author in here, let's can that
      // It comes through as an h3 with no class, helpful!
      > h3 {
        display: none;
      }
    }

    .postFooterWrapper {
      display: none !important; // Never
    }
  }
}

//
// Responsive
//

@include max-width(sm) {
  .searchFilterWrapper {
    flex-direction: column;
  }

  .moduleFilterContent {
    padding-right: 0;
    margin-bottom: $spacer;
  }
}

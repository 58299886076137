@if $menu-admin-enabled == false {
  .menuAdminContainer {
    display: none;
  }
}
@else {
  .menuAdminContainer {

    // Solid colour
    @if $newsletter-background-gradient == none {
      background-color: $menu-admin-background-colour;
    }

    // Gradient
    @else {
      background: $menu-admin-background-gradient;
    }

    width: 100%;
    max-width: $menu-admin-max-width;

    @include parentless-container-wrapper($menu-admin-max-width);

    margin: 0 auto;
    font-size: $menu-admin-font-size;

    @if $menu-admin-absolute == true {
      position: absolute;
      top: 0;
      z-index: 1;
    }
  }

  // https://trello.com/c/swyXMdg7/115
  // If you're logged in to an admin account, and have an absolute menu admin,
  // then the bar goes over the .adminBar and .adminBarEdit elements.
  // We're checking here whether we have those elements
  // then next there's a style block that gets added which contains some inline styling for the .highlighted outline
  // then finally the .menuAdminContainer
  // We know the admin bars are 60px hard coded tall, so we're good to account for that
  @if $menu-admin-absolute == true {
    .adminBarEdit + style + .menuAdminContainer,
    .adminBarEdit + style + .skipLink .menuAdminContainer {
      margin-top: 60px;
    }
  }

  #menuAdmin {
    @include list-unstyled;
    display: flex;
    width: 100%;
    max-width: $menu-admin-contents-max-width;
    padding: 0 calc(#{$site-bleed} - .5em); // Compensate for li padding
    margin: 0 auto;

    @if $menu-admin-align-items == left {
      justify-content: flex-start;
    }

    @if $menu-admin-align-items == center {
      justify-content: center;
    }

    @if $menu-admin-align-items == right {
      justify-content: flex-end;
    }

    li {
      padding: 0 .5em;
      margin: .5em 0;
    }

    a {
      color: $menu-admin-link-colour;
    }

    @if $menu-admin-login-link-enabled == false {
      .menuAdminLogin {
        display: none;
      }
    }

    @if $menu-admin-manager-link-enabled == false {
      .menuAdminManager {
        display: none;
      }
    }

    @if $menu-admin-my-details-link-enabled == false {
      .menuAdminDetails {
        display: none;
      }
    }

    @if $menu-admin-logout-link-enabled == false {
      .menuAdminLogOut {
        display: none;
      }
    }
  }

  // If we're turning the shop of site wide, hide the basket item from header
  @if $shop-enabled == false {
    .menuAdminBasket {
      display: none !important;
    }
  } @else {
    .menuAdminBasket {
      order: 4; // Keep at the end;
      &:before {
        content: '\f291';
        font-family: $icon-font-family;
        color: $menu-admin-link-colour;
        font-weight: normal;
        margin-right: .5em;
      }
    }

    @if $basket-link-only-on-shop-pages == true {
      body:not(.productsList):not(.productPage):not(.checkout) {
        .menuAdminBasket {
          display: none;
        }
      }
    }
  }

  // Social items added to the admin navigation
  #menuAdmin {
    a[href*="facebook.com"],
    a[href*="twitter.com"],
    a[href*="youtube.com"],
    a[href*="instagram.com"],
    a[href*="linkedin.com"] {
      font-size: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 5px;

      &:before {
        font-family: $icon-font-family;
        font-size: 1rem;
        font-weight: normal;
      }

      &:hover {
        text-decoration: none;
      }
    }

    a[href*="facebook.com"]:before {
      content: '\f09a';
    }

    a[href*="twitter.com"]:before {
      content: '\e61b';
    }

    a[href*="youtube.com"]:before {
      content: '\f16a';
    }

    a[href*="instagram.com"]:before {
      content: '\f16d';
    }

    a[href*="linkedin.com"]:before {
      content: '\f0e1';
    }

    a[href*="pinterest.com"]:before {
      content: '\f231';
    }

    a[href*="tripadvisor.co.uk"]:before {
      content: '\f262';
    }
  }

  // Menu admin into nav
  .menuAdminAltItems {
    display: none; // Hide by default
  }

}

// Permanently hide the 'sign up' link -
// We think it introduces a confusing user journey
.menuAdminRegister {
  display: none;
}
.postContent {
  width: 100%;
  max-width: $post-content-max-width;
  padding-left: $site-bleed;
  padding-right: $site-bleed;
  margin-left: auto;
  margin-right: auto;
}

.contentBlockWrapper {
  margin-top: $spacer * 3;
  margin-bottom: $spacer * 3;
}

.headerWrapper + .contentBlockWrapper {
  margin-top: 0;
}

.post {
  .contentBlockWrapper,
  .headerText .title {
    width: 100%;
    max-width: $post-content-max-width;
    padding-left: $site-bleed;
    padding-right: $site-bleed;
    margin-left: auto;
    margin-right: auto;
  }

  // We have some nested contentBlockWrapper elements
  // specifically on the donation page. We don't want to have
  // double lateral padding here, so reset it
  .contentBlockWrapper .contentBlockWrapper {
    padding: 0;
    margin: 0;
  }

  .contentBlock {
    margin-bottom: $spacer * 3;
  }

  // Reset this
  .postContent {
    padding-left: 0;
    padding-right: 0;
  }

  //
  // Post footer content
  //
  .postContent .postFooterWrapper {
    margin-top: $spacer * 3;
    clear: both;

    // Hide anything that isn't share wrapper
    // This is mostly UI clutter IMO
    .postFooter > *:not(.shareWrapper) {
      display: none;
    }
  }
}

// Set an overall 'page' background colour on a post
// Excluding products pages and faqs listing
@if $post-body-background-colour != transparent {
  body:not(.productsList):not(.productPage):not(.faqsList) article.post {
    background-color: $post-body-background-colour;
  }
}

// Set better margins on cta-buttons within post-content
.postContent .cta-button {
  margin: 5px;
  @include max-width(sm) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

#postFooterArea {
  margin-bottom: $gap-width;
}

// DES-3585
// When an event post has an associated appeal, the appeal's title and summary text get added into the post content
// Which is slightly weird I think. So we're going to just hide this content.
.postContent.eventContent .associatedAppeal {
  display: none;
}

// Sometimes clients put links in their post content
// And these can be really long, with no breaking characters
// So let's force links within post content to break word
@media (max-width: map-get($breakpoints, md)) {
  .postContent,
  .listingIntro {
    a {
      word-break: break-word;
    }
  }
}

// The Recaptcha popup is another fraud detection system that we use on payments
// if the user's payment is under suspicion.

.recaptcha-container {
  display: none; /* Hidden by default */
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.6);
}

.recaptcha-content {
  background-color: #fff;
  margin: 0;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.recaptcha-intro {
  display: block;
  margin: 0 auto 10px;
}

.recaptcha-btn {
  display: table;
  margin: 0 auto;
  width: 304px;
}

@if $shop-enabled == true {
  @include min-width(md) {
    .shopLandingPage .post .contentBlock,
    .shopListingPage .listing .contentBlockWrapper {
      display: flex;
      flex-direction: row;
    }
  }

  .shopLandingContent,
  .shopListingWrapper .contentBlock .listContent {
    display: flex;
    flex-wrap: wrap;
    margin-left: ($card-gap-width / 2) * -1;
    margin-right: ($card-gap-width / 2) * -1;
  }

  // Make the landing page respect $listing-content-max-width
  .shopLandingPage .post {
    .contentBlockWrapper,
    .headerText .title {
      max-width: $listing-content-max-width;
    }
  }

  .contentBlock.productList {
    width: 100%; // Without this we get a bug in IE, some Flexbox issue
  }

  // When we have a departments sidebar, go for 2 columns
  .departments + section .listedProduct {
    @include min-width(lg) {
      @include card-row(2);
    }

    // Force one column at lg otherwise rule below will be applied to these elements
    @media (max-width: map-get($breakpoints, lg)) {
      width: 100%;
    }
  }

  // Otherwise use 3 columns
  // I.e. when no departments
  .listedProduct {
    @include min-width(lg) {
      @include card-row(3);
    }

    @media (min-width: map-get($breakpoints, md) + 1px) and (max-width: map-get($breakpoints, lg)) {
      @include card-row(2);
    }
  }

  .shopLandingContent .content {
    margin: 0 ($card-gap-width / 2);
    width: 100%;
  }
}

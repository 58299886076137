@if $shop-enabled == true and $product-rating-enabled == true {
  .footerProductReviews {
    margin-bottom: $gap-width / 2;
  }

  .productRating {
    width: 100%;

    legend {
      @extend h6;
    }
  }

  .formQuestion.ratingOption {
    display: inline-flex;
    padding: 0;
    margin: 0 10px 0 0;
    align-items: center;
  }

  .starRating {
    @include list-unstyled;
    display: flex;
    font-size: 0; // Hide the '1 star' etc. text. let's use an icon

    li:before {
      content: "\f006";
      font-family: $icon-font-family;
      font-size: 1rem;
      color: #febd00;
    }

    li.active:before {
      content: "\f005";
    }
  }

  .commentsList .starRating li {
    border: none;

    &:first-child {
      border: none;
    }
  }

  .reviewNumber {
    font-size: $font-size-small;
  }
}

@if $carousel-controls-enabled == false {
  .carouselControls {
    display: none;
  }
} @else {
  .carouselControls {
    @include list-unstyled;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none; // We want to be able to click through this absolute parent
    padding-top: $carousel-controls-margin-y;
    padding-bottom: $carousel-controls-margin-y;
    padding-left: $carousel-controls-margin-x;
    padding-right: $carousel-controls-margin-x;

    @if $carousel-max-width != 100% {
      left: $site-bleed;
      right: $site-bleed;
    }

    @if $carousel-controls-position-y == center {
      align-items: center;
    }

    @if $carousel-controls-position-y == bottom {
      align-items: flex-end;
    }

    @if $carousel-controls-position-x == split {
      justify-content: space-between;
    }

    @if $carousel-controls-position-x == right {
      justify-content: flex-end;
    }
  }

  .carouselControls li {
    pointer-events: all; // Reintroduce pointer events here
    &:last-child {
      order: -1; // They're the wrong way round in the HTML so invert them here
    }
  }

  .carouselControls a {
    display: block;
    width: $carousel-controls-button-size;
    height: $carousel-controls-button-size;
    overflow: hidden;
    background-color: $carousel-controls-button-background-colour;
    border-radius: $carousel-controls-button-border-radius;
    text-decoration: none;
    color: $carousel-controls-icon-colour;
    box-shadow: $carousel-controls-button-box-shadow;
    transition: all $btn-transition-duration $transition-curve;

    &:before {
      display: block;
      font-family: $icon-font-family;
      line-height: $carousel-controls-button-size;
      width: $carousel-controls-button-size;
      height: $carousel-controls-button-size;
      text-align: center;
      font-size: $carousel-controls-icon-font-size;
    }

    &:hover {
      @include button-hover-state($carousel-controls-button-background-colour);
    }
  }

  .carouselControlNext {
    margin-left: $carousel-controls-button-gap;
  }

  .carouselControlNext:before {
    content: $carousel-controls-next-icon;
  }

  .carouselControlPrev:before {
    content: $carousel-controls-prev-icon;
  }

  // If we have carousel controls enabled AND buttons set to tabs, we need to offset for the relative tabs at the bottom of the carousel
  @if ($carousel-buttons-enabled == true) and ($carousel-buttons-type == tabs) and ($carousel-controls-enabled == true) and ($carousel-controls-position-y == bottom) {
    .carousel {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
    }

    .carouselSlides,
    .carouselControls {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    .carouselControls {
      left: 0;
      right: 0;
    }
  }

  @include max-width(sm) {
    @supports (object-fit: cover) {
      .carouselControls {
        min-height: $banner-min-height;
      }
    }
  }
}


.eventWidgetsWrapper {
  margin-bottom: $gap-width;
  // Always display on top
  order: -1;
  

  // Order elements
  .eventDetails {
    display: flex;
    flex-direction: column;
    .eventWhenWrapper {
      order: -1;
    }
  }
}

// "Event details" - this is UI clutter
.eventDetailsTitle {
  display: none;
}

.eventDetails section {
  margin-top: $spacer;
  margin-bottom: $spacer;
}

.eventLabel {
  @extend h5;

  // Icon
  &:before {
    content: '';
    font-family: $icon-font-family;
    display: inline-block;
    margin-right: .5rem;
    font-weight: normal;
  }
}

.eventWhereWrapper .eventLabel:before {
  content: '\f041';
}

.eventWhenWrapper .eventLabel:before {
  content: '\f073';
}

.mapLink {
  margin-top: $spacer;
}
.mainLogo {
  text-indent: -10000px;
  display: inline-block;
  width: $logo-width;
  height: $logo-height;
  background-image: url($assets-path + $logo);
  background-size: contain;
  background-position: center left;
  background-repeat: no-repeat;
  margin-right: $spacer;
  z-index: 2;

  @media
  only screen and (        min-device-pixel-ratio: 2),
	only screen and (                min-resolution: 192dpi),
	only screen and (                min-resolution: 2dppx) {
    background-image: url($assets-path + $logo-retina);
  }
}

@if $logo-absolute == true {
  @include min-width(lg) {
    .pageHeader .headerContent {
      position: relative;
      padding-left: calc(#{$logo-width} + #{$spacer} + #{$site-bleed});
    }

    .mainLogo {
      position: absolute;
      top: 0;
      left: $site-bleed;
    }
  }
}


//
// Responsive
//

@media (max-width: $nav-breakpoint) {
  .mainLogo {
    width: $logo-width * .75;
    height: $logo-height * .75;
    max-width: calc(100% - 110px); // Safe clearance of the menu main alt
  }
}

@media (max-width: $logo-breakpoint) {
  .mainLogo {
    background-image: url($assets-path + $logo-mobile);
    width: $logo-mobile-width;
    height: $logo-mobile-height;

    @media
    only screen and (        min-device-pixel-ratio: 2),
    only screen and (                min-resolution: 192dpi),
    only screen and (                min-resolution: 2dppx) {
      background-image: url($assets-path + $logo-mobile-retina);
    }
  }
}
@mixin card-text-overlay(
  $card-text-overlay-show-summary-on-hover:
    $card-text-overlay-show-summary-on-hover,
  $card-text-overlay-summary-enabled: $card-summary-enabled,
  $card-text-overlay-details-max-width: $card-text-overlay-details-max-width,
  $card-text-overlay-details-position-y: $card-text-overlay-details-position-y,
  $card-text-overlay-details-position-x: $card-text-overlay-details-position-x
) {
  position: relative;

  #{$card-details} {
    position: absolute;

    // Vertical positioning
    @if $card-text-overlay-details-position-y == bottom {
      bottom: 0;
    }

    @if $card-text-overlay-details-position-y == top {
      top: 0;
    }

    @if $card-text-overlay-details-position-y == center {
      top: 50%;
      transform: translateY(-50%);
    }

    // Lateral positioning
    @if $card-text-overlay-details-position-x == left {
      left: 0;
    }

    @if $card-text-overlay-details-position-x == center {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }

    @if $card-text-overlay-details-position-x == right {
      right: 0;
    }

    @if $card-text-overlay-details-max-width != 100% {
      max-width: $card-text-overlay-details-max-width;
    }

    h2,
    .feedTitle {
      transition: margin-bottom $transition-duration $transition-curve;
    }

    > p {
      transition: max-height $transition-duration $transition-curve;
    }

    > p:last-of-type {
      margin-bottom: 0;
    }
  }

  @if ($card-text-overlay-show-summary-on-hover == true) and
    ($card-text-overlay-summary-enabled == true)
  {
    #{$card-details} {
      h2,
      .feedTitle {
        margin-bottom: 0;
      }

      > p {
        opacity: 0;
        max-height: 0;
      }
    }

    &:hover {
      #{$card-details} {
        h2,
        .feedTitle {
          margin-bottom: $headings-margin-bottom;
        }

        > p {
          opacity: 1;
          max-height: 500px;
        }
      }
    }
  }

  footer {
    display: none; // This is never going to work
  }

  //
  // Responsive
  //
  @media (max-width: $card-text-overlay-breakpoint) {
    flex-direction: column;
    
    #{$card-details} {
      position: relative;
      max-width: 100%;
      top: auto;
      bottom: auto;
      transform: none;
    }
  }
}

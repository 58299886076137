@media print {
  .menuAdminContainer,
  .headerContent > *:not(.mainLogo),
  .menuMain,
  a.menuMainAlt,
  .postAside,
  .pageFooterWrapper,
  .NewsletterSign-up,
  .footerBox,
  .footerBox.Footerlogos,
  .embed-container,
  .shareWrapper {
    display: none;
  }

  .postContent {
    padding-right: 0;
    padding-left: 0;
    width: 100%;
  }

  body {
    font-size: .9em;
  }
}
@if $carousel-buttons-enabled == false {
  .carouselButtons {
    display: none;
  }
} @else {
  .carouselButtons {
    @include list-unstyled;
    display: flex;
  }
}

@if $carousel-buttons-type == dots {
  .carouselButtons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    padding: $carousel-dots-padding;

    li {
      pointer-events: all;
    }

    @if $carousel-max-width != 100% {
      left: $site-bleed;
      right: $site-bleed;
    }

    @if $carousel-dots-position-y == bottom {
      align-items: flex-end;
    }

    @if $carousel-dots-position-x == center {
      justify-content: center;
    }

    @if $carousel-dots-position-x == right {
      justify-content: flex-end;
    }
  }

  .carouselButtons a {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    font-size: 0;
    background-color: rgba($carousel-dots-colour, $carousel-dots-opacity);
    margin: 0 4px;
    padding: 0;

    &:hover {
      background-color: $carousel-dots-colour;
    }
  }

  .carouselButtons .activeSlide a {
    background-color: $carousel-dots-active-colour;

    @if $carousel-mobile-details-background-colour == $brand-primary {
      @media (max-width: $carousel-breakpoint) {
        background-color: darken($brand-primary, 10%);
      }
    }
  }
}

@if $carousel-buttons-type == tabs {
  .carouselButtons li {
    flex: 1;
    background-color: $carousel-tabs-background-colour;
    text-align: $carousel-tabs-text-align;
  }

  .carouselButtons .carouselButton {
    padding: $carousel-tabs-padding-y $carousel-tabs-padding-x;
    background-color: transparent; // Let the track background show thorugh
    margin: 0; // Remove any button margin
    display: block;
    color: $carousel-tabs-colour;
    font-size: $carousel-tabs-font-size;
    text-transform: $carousel-tabs-text-transform;
    text-align: $carousel-tabs-text-align;
    line-height: $headings-line-height;
    font-weight: $carousel-tabs-font-weight;

    @if $carousel-tabs-separator-width != 0px {
      border-left: ($carousel-tabs-separator-width / 2) solid $carousel-tabs-separator-colour;
      border-right: ($carousel-tabs-separator-width / 2) solid $carousel-tabs-separator-colour;
    }
  }

  .carouselButtons .activeSlide .carouselButton {
    background-color: $carousel-tabs-active-background-colour;
    color: $carousel-tabs-active-colour;

    @if $carousel-tabs-active-triangle-enabled == true {
      position: relative;
      &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 15px 15px;
        border-color: transparent transparent $carousel-tabs-active-background-colour transparent;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: -15px;
      }
    }
  }
}
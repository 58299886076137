// Post content image - Layout
.image-align-right,
.image-align-left,
.mediaImage {
  max-width: 100%;
}


// Post content image - Layout
.postContent .mediaImage {
  height: auto !important;
}


// Post content image - Responsive
@include max-width(sm) {
  .image-align-right,
  .image-align-left,
  img.postImageFloatLeft,
  img.postImageFloatRight {
    display: block;
    width: 100%;
    float: none;
    margin: 15px auto;
  }
}


// Custom style - Image full width
img.full-width {
  display: block;
  width: 100%;
  margin: 20px 0;
}


// Custom style - Image full screen
img.full-screen {
  @if $sidebar-layout != adjacent {
    display: block;
    width: 100vw;
    margin: 20px 0;
    max-width: 100vw;
    position: relative;
    left: calc((100vw - (#{$post-content-max-width} - #{$site-bleed * 2})) / -2);
    @media (max-width: $post-content-max-width) {
      left: -$site-bleed;
    }
  } @else {
    display: block;
    width: 100%;
    margin: 20px 0;
  }
}
table {
  width: 100% !important;
  max-width: 100%;
  height: auto !important;
  margin: $spacer auto;
  background-color: transparent;
}

th,
td {
  padding: $table-cell-padding;
  border-top: 1px solid $border-colour;
}

th,
thead td {
  vertical-align: bottom;
  font-weight: $font-weight-bold;
  border-bottom: 2px solid $border-colour;
}

td {
  vertical-align: top;
}

// Some global styles here
table {
  .itemName {
    font-size: 1rem;
    font-weight: $font-weight-normal;
    margin: 0;
  }

  .itemDescription {
    margin: 0;
  }

  .itemQuantity {
    display: none; // UI clutter
  }

  .quantityColumn input {
    max-width: 60px;
    text-align: center;
  }

  .itemRow .itemColumn {
    .itemThumb,
    .itemName {
      display: inline-block;
      vertical-align: middle;
    }

    .itemThumb {
      margin-right: 0.5rem;
    }
  }
}

// Some sensible responsive post content tables
@media (max-width: map-get($breakpoints, md)) {
  .postContent table:not(.shoppingList) {
    display: block;
    overflow: scroll;

    td {
      min-width: 120px;
    }
  }
}

@mixin card (
  $card-border-radius: $card-border-radius,
  $card-border: $card-border,
  $card-box-shadow: $card-box-shadow,
  $card-text-align: $card-text-align,
  $card-image-on: $card-image-on,
  $card-image-opacity: $card-image-opacity,
  $card-image-background-colour: $card-image-background-colour,
  $card-image-padding: $card-image-padding,
  $card-image-border-radius: $card-image-border-radius,
  $card-details-padding: $card-details-padding,
  $card-details-background-colour: $card-details-background-colour,
  $card-details-background-gradient: $card-details-background-gradient,
  $card-heading-font-family: $card-heading-font-family,
  $card-heading-text-transform: $card-heading-text-transform,
  $card-heading-colour: $card-heading-colour,
  $card-heading-font-size: $card-heading-font-size,
  $card-heading-margin-bottom: $card-heading-margin-bottom,
  $card-summary-enabled: $card-summary-enabled,
  $card-summary-colour: $card-summary-colour,
  $card-summary-font-size: $card-summary-font-size,
  $card-footer-on: $card-footer-on,
  $card-footer-background-colour: $card-footer-background-colour,
  $card-footer-background-gradient: $card-footer-background-gradient,
  $card-footer-colour: $card-footer-colour,
  $card-footer-padding: $card-footer-padding,
  $card-gap-width: $card-gap-width,
  $card-hover-border-colour: $card-hover-border-colour,
  $card-hover-box-shadow: $card-hover-box-shadow,
  $card-hover-image-opacity: $card-hover-image-opacity,
  $card-hover-image-scale: $card-hover-image-scale,
  $card-hover-details-background-colour: $card-hover-details-background-colour,
  $card-hover-details-background-gradient: $card-hover-details-background-gradient,
  $card-hover-heading-colour: $card-hover-heading-colour,
  $card-hover-summary-colour: $card-hover-summary-colour
) {
  margin: ($card-gap-width / 2);
  border-radius: $card-border-radius;
  border: $card-border;
  box-shadow: $card-box-shadow;
  display: flex;
  overflow: hidden;
  text-align: $card-text-align;
  transition: all $transition-duration $transition-curve;
  position: relative;

  > * {
    max-width: 100%;
  }

  @if $card-image-on == false {
    .banner {
      display: none;
    }
  } @else {
    #{$card-image-link} {
      padding: $card-image-padding;
      background-color: $card-image-background-colour;
      flex-shrink: 0;
      width: 100%;
      overflow: hidden;
    }

    .banner {
      opacity: $card-image-opacity;
      display: block;
      width: 100%;
      max-width: initial;
      transition: all $transition-duration $transition-curve;
      border-radius: $card-image-border-radius;

      @supports (object-fit: cover) {
        object-fit: cover;
        object-position: center;
        height: 100%;
      }
    }
  }

  #{$card-details} {
    width: 100%;
    padding: $card-details-padding;
    transition: all $transition-duration $transition-curve;

    // Solid colour
    @if $card-details-background-gradient == none {
      background-color: $card-details-background-colour;
    }

    // Else, gradient
    @else {
      background: $card-details-background-gradient;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  .feedTitle {
    font-family: $card-heading-font-family;
    font-size: $card-heading-font-size;
    margin-bottom: $card-heading-margin-bottom;

    @if $card-heading-text-transform != none {
      text-transform: $card-heading-text-transform;
    }

    a {
      color: $card-heading-colour;
      transition: all $transition-duration $transition-curve;

      &:hover {
        color: darken($card-heading-colour, 10%);
      }
    }
  }

  h3 {
    color: $card-heading-colour;
  }

  // Listed fundraisers get an h3 here for the fundraiser name
  .listedPostText > h3 {
    font-size: $font-size-h5;
  }

  @if $card-summary-enabled == false {
    h2,
    .feedTitle {
      margin-bottom: 0;
    }

    #{$card-details} {
      > p {
        display: none;
      }
    }
  } @else {
    #{$card-details} {
      > p {
        margin-bottom: 0;
        color: $card-summary-colour;
        font-size: $card-summary-font-size;
        transition: all $transition-duration $transition-curve;
      }
    }
  }

  @if $card-footer-on == false {
    footer {
      display: none;
    }
  }

  footer {
    padding: $card-footer-padding;
    font-size: $font-size-small;
    color: $card-footer-colour;
    width: 100%;

    // Solid background colour
    @if $card-footer-background-gradient == none {
      background-color: $card-footer-background-colour;
    }

    // Gradient
    @else {
      background: $card-footer-background-gradient;
    }

    .postFooter > *:not(.button) {
      opacity: .75; // Slightly fade it
    }

    .shareWrapper {
      display: none; // never
    }

    p {
      margin: 4px 0;
      display: none; // Opt these in
    }

    .relatedWrapper {
      display: none;
    }

    // Hide the labels
    strong {
      display: none;
    }

    // Hide times, just leave date
    time span:not(:first-child) {
      display: none;
    }

    // Icons
    p:before {
      content: '';
      font-family: $icon-font-family;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      width: 14px;
      text-align: center;
    }

    .publishDatesWrapper:before,
    .associatedStartDate:before {
      content: '\f073';
    }

    .publishAuthorWrapper:before {
      content: '\f007';
    }

    .locationDetails:before {
      content: '\f041';
    }

  }

  &:hover {
    cursor: pointer; // Whole thing is a link
    border-color: $card-hover-border-colour;
    box-shadow: $card-hover-box-shadow;

    #{$card-image-link} .banner {
      opacity: $card-hover-image-opacity;
      transform: scale($card-hover-image-scale);
    }

    #{$card-details} {
      // Solid colour
      @if $card-hover-details-background-gradient == none {
        background: initial; // Remove any existing gradient
        background-color: $card-hover-details-background-colour;
      }

      // Gradient
      @else {
        background: $card-hover-details-background-gradient;
      }
    }

    h2, .feedTitle {
      color: $card-hover-heading-colour;
      a {
        color: $card-hover-heading-colour;
      }
    }

    @if $card-summary-enabled == true {
      #{$card-details} {
        > p,
        > .productPrice {
          color: $card-hover-summary-colour;
        }
      }
    }
  }
}

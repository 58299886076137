@if $donation-form-frequency-tabs == true {
  .formQuestion.donationFrequency {
    padding: 0;
    border-radius: 0;
    border: 0;
    flex-direction: row;
    align-items: stretch;
    margin-bottom: $gap-width;

    > div {
      flex: 1;
      padding: 15px;
      border: 1px solid $border-colour;
      text-align: center;
      font-weight: $font-weight-bold;
      background-color: #fff;
      color: $body-colour;
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 15px 0 15px;
        border-color: $donate-colour transparent transparent transparent;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 0;
        opacity: 0;
        transition: bottom $transition-duration-slow $transition-curve;
      }

      &.active {
        background-color: $donate-colour;
        color: text-contrast($donate-colour);

        // Triangle
        &:after {
          opacity: 1;
          bottom: -15px;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    #Single {
      border-right: 0;
    }
  }

  .formQuestion.donationPeriod {
    padding-top: 15px;
  }

  // We get these tabs on fundraiser donation forms even though you can't set
  // up a regular donation on fundraiser appeals...! So just hide
  .individualfundraiser .formQuestion.donationFrequency {
    display: none;
  }


  //
  // Responsive
  //

  @include max-width(sm) {
    .formQuestion.donationFrequency {
      font-size: 1em;
    }
  }

  @media (max-width: 360px) {
    .formQuestion.donationFrequency {
      flex-direction: column;
      > div:after {
        display: none;
      }
    }
  }
}
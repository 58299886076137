.customForm {
  padding-top: $header-text-margin-top;
  margin-bottom: $spacer * 3;

  h1 {
    font-size: $page-title-font-size;
    font-family: $page-title-font-family;
    font-weight: $page-title-font-weight;
    color: $page-title-colour;
    text-transform: $page-title-text-transform;
    margin: $page-title-margin-top 0 $page-title-margin-bottom;
    text-align: $page-title-text-align;

    @media (max-width: map-get($breakpoints, md)) {
      font-size: $page-title-md-font-size;
    }

    @media (max-width: map-get($breakpoints, sm)) {
      font-size: $page-title-sm-font-size;
    }
  }

  h2, h3, h4, h5 {
    color: $page-title-colour;
  }

  .buttonContainer {
    width: 100%;
    max-width: $post-content-max-width;
    padding-left: $site-bleed;
    padding-right: $site-bleed;
    margin: $gap-width auto 0;
    text-align: center;
  }
}

.answerOptions {
  display: flex;
  flex-direction: column;

  > *[class*="LabelPair"] {
    display: inline-flex;
    align-items: center;
    margin: 0 0 .75em 0;
  }

  label {
    margin: 0 0 0 .5em;
    flex-grow: 1;
  }
}

// Users can set a 'checkbox' type question to optionally display as a 'grid' in the manager
// This changes the .formQuestion class from .checkboxRadioList to .checkboxRadioGrid
// I'm writing some CSS here to style specifically this class in custom forms as a grid
// rather than globally, as I suspect this class might be used elsewhere in the application
// where we don't really want this behaviour

// Wrap this in an @supports rule to exclude IE11, which doesn't like grid-template-columns
// IE11 will not render this block because it ironically doesn't support @supports
@supports(display: grid) {
  @include min-width(sm) {
    .customForm .checkboxRadioGrid .answerOptions {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: $spacer;
    }
  }

  @media  (max-width: map-get($breakpoints, lg)) and (min-width: map-get($breakpoints, sm) + 1px) {
    .customForm .checkboxRadioGrid .answerOptions {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

// Also lumping in some of the odd lobby forms, such as /signup in here
#bodyForm > .registerForm {
  padding-top: $header-text-margin-top;
  width: 100%;
  max-width: $post-content-max-width;
  padding-left: $site-bleed;
  padding-right: $site-bleed;
  margin: $gap-width auto ($spacer * 3);
}
// A snippet can be placed in the 'Home Headlines Intro' introduction position
// This will then appear BEFORE the home feature cards and be excluded
// from the home feature card prefab layouts

@if $home-headlines-intro-enabled {
  .homeHeadlinesIntro {
    position: relative;
    width: 100%;
    max-width: $home-headlines-intro-max-width;
    margin: $home-headlines-intro-margin-top auto $home-headlines-intro-margin-bottom;
    padding: $home-headlines-intro-padding-top 0 $home-headlines-intro-padding-bottom;
    text-align: $home-headlines-intro-text-align;
    font-family: $home-headlines-intro-font-family;
    font-size: $home-headlines-intro-font-size;
    color: $home-headlines-intro-colour;
    background-color: $home-headlines-intro-background-colour;

    * {
      font-size: inherit;
    }

    > * {
      padding: 0 $site-bleed;
      width: 100%;
      max-width: $home-headlines-intro-content-max-width;
      margin-left: auto;
      margin-right: auto;
      text-transform: $home-headlines-intro-text-transform;

      &:last-child {
        margin-bottom: 0;
      }
    }

    // Turn off the actual snippet title?
    .homeFeaturesTitle {
      @if $home-headlines-intro-snippet-title-enabled == false {
        display: none;

        + * {
          margin-top: 0;
        }
      } @else {
        margin-top: 0;
      }
    }
  }

  //
  // Responsive
  //
  @include max-width(lg) {
    .homeHeadlinesIntro {
      margin-top: $home-headlines-intro-margin-top * .5;
      margin-bottom: $home-headlines-intro-margin-bottom * .5;
    }
  }

  @include max-width(md) {
    .homeHeadlinesIntro {
      padding: ($home-headlines-intro-padding-top * .7) 0 ($home-headlines-intro-padding-bottom * .7);
      font-size: $home-headlines-intro-font-size * .85;
    }
  }
}

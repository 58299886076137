
body {
    overflow-x: hidden;
}

// Admin bar
.adminBar, .adminBarEdit {
    z-index: 2;
}

// Nav Desktop Layout
@media (min-width: #{$nav-breakpoint + 1px}) {
    ul.topLevel {
        position: relative;
        justify-content: space-between;
        z-index: 1;
        &:before {
            content: '';
            position: absolute;
            width: 100vw;
            height: 65px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url($assets-path + 'nav-sand.png');
            background-position: center;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            z-index: -1;
        }
    }
}


// Burger button - Hover
.menuMainAlt:hover, .menuMainAlt.active {
    background-color: darken($burger-btn-background-colour, 10%);
    color: $burger-btn-text-colour;
}

// Carousel details - Layout
.carouselSlideSummary {
    padding-top: $spacer;
    border-top: 1px solid text-contrast($carousel-details-background-colour);
}


// Home headline intro - Format
.homeHeadlinesIntro span {
    color: $_orange;
    font-size: $font-size-h3;
    text-transform: $headings-text-transform;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
}


// Header sticky desktop - Layout
@media (min-width: map-get($breakpoints, lg) + 1px) {
    .pageHeader.sticky {
        .headerContent { display: none; }
        ul.topLevel { border-top: none; }
    }
}


// Header mobile - Layout
@media (max-width: map-get($breakpoints, lg)) {
    .pageHeader .headerContent {    
        margin-top: $header-sticky-header-content-margin-top;
        margin-bottom: $header-sticky-header-content-margin-bottom;
    }
}


// Homepage stats - Background
.homefeaturecategory-homeboximpactstats:before {
    background-attachment: fixed;
}

// Home feeds - Layout
.homeFeedBox2 {
    position: relative;
    z-index: 1;
    
    // Background
    &:before {
        content: '';
        position: absolute;
        width: 100vw;
        height: 100%;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        background-color: $grey-lightest;
        z-index: -1;
    }

    &:after {
        content: '';
        position: absolute;
        width: 100vw;
        height: 65px;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url($assets-path + 'nav-sand.png');
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: -1;
        @media (max-width: map-get($breakpoints, md)) {
            height: 25px;
        }
    }
}


// Home features - Background
.homeFeaturesWrapper .homeFeatures {
    position: relative;
    z-index: 2;
    &:after {
        content: '';
        position: absolute;
        width: 100vw;
        height: 65px;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url($assets-path + 'nav-white.png');
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: -1;
        @media (max-width: map-get($breakpoints, md)) {
            height: 25px;
        }
    }
}

// Home feeds - Layout
.homeFeedBox1 {
    position: relative;
    z-index: 2;
    &:before, &:after {
        content: '';
        position: absolute;
        width: 100vw;
        height: 65px;
        left: 50%;
        background-image: url($assets-path + 'nav-white.png');
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: -1;
        @media (max-width: map-get($breakpoints, md)) {
            height: 25px;
        }
    }
    &:before {
        bottom: 100%;
        transform: translate(-50%, 50%);
    }
    &:after {
        top: 100%;
        transform: translate(-50%, -50%);
    }
}


// Feeds title - Format
.homeFeed .feedsTitle {
    position: relative;
    margin-bottom: calc(#{$spacer * 5} - #{$card-gap-width / 2});
    z-index: 1;
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100px;
        width: 100px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1;
    }
}
.homeFeedBox3 .feedsTitle:before {
    background-image: url($assets-path + 'star-yellow.svg');
}


// Feed items + Listed Posts - Format
.homeFeedBox1 .feedList .feedItem [class*="DetailsWrapper"]:not(.publishDetailsWrapper) > p,
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem):not(.module-news ):not(.module-blog):not(.PostCategory_your-stories) [class^="listed"][class$="Text"] > p {
    margin-top: $spacer; 
    padding-top: $spacer;
    border-top: 1px solid $white;
}

.homeFeed:not(.homeFeedBox1) .feedList .feedItem [class*="DetailsWrapper"]:not(.publishDetailsWrapper) > p,
.module-news [class^="listed"][class$="Text"] > p,
.module-blog [class^="listed"][class$="Text"] > p,
.PostCategory_your-stories [class^="listed"][class$="Text"] > p {
    margin-top: $spacer; 
    padding-top: $spacer;
    border-top: 1px solid $body-colour;
}


// Card footer content - Format
.homeFeedBox1 .feedList .feedItem footer .postFooter > *,
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) footer .postFooter > * {
    opacity: 1;
}


// Footer - Background
.pageFooterWrapper {
    position: relative;
    z-index: 1;
    overflow: hidden;
    &:before {
        content: '';
        position: absolute;
        width: 100vw;
        height: 65px;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, +50%);
        background-image: url($assets-path + 'nav-sand.png');
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: -1;
        @media (max-width: map-get($breakpoints, md)) {
            height: 25px;
        }
    }
}


 // Footer - Mailchimp newsletter
 .footerBox[class*='ewsletter'] #mc-embedded-subscribe-form {
    position: relative;
    width: 100%;
    max-width: $container-max-width;
    margin: 0 auto;
    padding: 0 $site-bleed;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    text-align: center;
    
    > h1 {
        width: 100%;
        flex: unset;
        font-size: $newsletter-heading-font-size;
        font-family: $newsletter-heading-font-family;
        text-align: $newsletter-heading-text-align;
        text-transform: $newsletter-heading-text-transform;
        margin-bottom: $newsletter-heading-margin-bottom;
        color: $newsletter-heading-colour;
    }
    > p {
        width: 100%;
        flex: unset;
        font-size: $font-size-base;
        color: $newsletter-colour;
    }
    .indicates-required { 
        display: none;
    }
    .mc-field-group {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-width: $card-min-width;
        padding: 0 #{$card-gap-width / 4} #{$card-gap-width / 4} #{$card-gap-width / 4};
        margin-top: $spacer;
        label {
            font-size: $font-size-small;
        }
    }
    .clear:last-of-type {
        padding: #{$card-gap-width / 4};
        margin-top: $spacer;
    }
    .cta-button {
        height: $newsletter-button-height;
        background-color: $newsletter-button-background-colour;
        color: $newsletter-button-colour;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 0;
        &:hover {
            background-color: darken($newsletter-button-background-colour, 10%);
        }
    }
    // Mobile layout
    @media (max-width: map-get($breakpoints, lg)) {
        flex-direction: column;
        justify-content: center;
        align-self: center;
        * { width: 100%; }
    }
 }


 // Footer logos
 .footerBox[class*='ogos'] {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    img {
        filter: invert(1);
        margin: $spacer #{$spacer * 1.5};
        max-height: 55px;
        object-fit: contain;
    }
 }


 // Footer content - Format
 .footerBox[class*='credits'] {
     margin-top: 0;
     ul { margin: $spacer auto; }
     @media (min-width: map-get($breakpoints, md) + 1px) {
        ul[class*='inks'] {
            display: flex;
            flex-wrap: wrap;
            li {
                width: auto;
                margin: 0 $spacer;
            }
        }
    }
 }

// Donate button - Format
.cta-button.donate, .button.donate, .donateBtn, button.donate {
  @include button (
    $btn-background-colour: $donate-btn-background-colour,
    $btn-text-colour: $donate-btn-colour
  );
}

// Donate form - Contrast format
.formQuestion.donationAmount:not(.ccAmount) .donationAmountFigure,
.donationGiftAid .donationGiftAidAmount,
.coverCostLabel,
.commentDonationAmount {
    color: $body-colour;
}


// Sidebar format
.associated:not(.associatedBox) li h3 {
    font-family: $font-family-base;
    text-transform: none;
}


// Post content iframe
iframe {
   width: 100%;
   margin: #{$spacer * 2} 0;
   border-radius: $border-radius;
}


// Post content 
.postContent .mediaImage {
    border-radius: $border-radius;
}


// Post content - Client style overwrite
*[style*="color: #993366;"] {
    color: $_orange !important;
}


// Bespoke layout - Contact us 
.postAside.officeFeedWrapper {
    order: -1;
    background-color: $_yellow;
    color: text-contrast($_yellow);
    padding: $context-container-padding;
    border-radius: $context-container-border-radius;
    .contactOfficeMapBtn {
        background-color: $_orange;
        color: white;
    }
}

// Post content table
.postContent table {
    border: none;
    td { border: none; }
}

// Blockquote format
blockquote {
    * { display: inline; }
    &:before, &:after {
        display: inline-block;
        margin-left: 7.5px;
        margin-right: 7.5px;
        font-family: $icon-font-family;
    }
    &:before { content: '\f10d'; }
    &:after { content: '\f10e'; }
}


// Post content button + client overwrite
a.BlueButton, a.OrangeButton {
    @include button (
        $btn-background-colour: $btn-background-colour,
        $btn-text-colour: $btn-text-colour,
    )
}
a.GreenButton , a.YellowButton {
    @include button (
        $btn-background-colour: $donate-btn-background-colour,
        $btn-text-colour: $donate-btn-colour,
    )
}

// Carousel
.carouselDetailWrapper {
    background-image: url($assets-path + 'carousel-background.png');
    background-repeat: no-repeat;
    background-size: 102% auto;
    background-position-x: -100px;
    @media (min-width: map-get($map: $breakpoints, $key: lg) + 1) {
        min-width: 1690px;
    }
}

// Intro
.homeIntro {
    strong {
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        text-transform: uppercase;
        max-width: 800px;
        display: block;
        margin: auto;
    }
    &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        background-size: 100%;
        background-repeat: no-repeat;
        z-index: -1;
    }
    @media (min-width: 1025px) {
        &:before {
            height: 200px;
            width: 200px;
            left: -80px;
            top: -70px;
            background-image: url($assets-path + 'arrow-yellow.png');
        }
    }
    &:after {
        height: 400px;
        width: 400px;
        right: -250px;
        top: -150px;
        background-image: url($assets-path + 'star-yellow.svg');
        overflow-x: hidden;
    }
}

// Stats
.homeImpactWrapper {
    &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        background-size: 100%;
        background-repeat: no-repeat;
    }
    &:before {
        background-image: url($assets-path + "star-orange.svg");
        width: 250px;
        height: 250px;
        left: -250px;
    }
    &:after {
        background-image: url($assets-path + "smile-orange.png");
        width: 150px;
        height: 150px;
        right: -150px;
    }
}

.homefeaturecategory-homeboximpactstats .homeImpactWrapper > h2 {
    display: block;
    font-size: 3rem;
}

// Feeds
.homeFeedBox1 .feedList {
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        background-size: 100%;
        background-repeat: no-repeat;
        height: 500px;
        width: 500px;
        right: -210px;
        top: -240px;
        background-image: url($assets-path + 'star-yellow.svg');
    }
}

// Footer
.footerBox[class*="ewsletter"] {
    background-color: transparent;
}

@media (min-width: 1240px) {
    .footerBox2 {
        overflow: hidden;
        &:before {
            content: '';
            display: block;
            position: absolute;
            background-size: 100%;
            background-repeat: no-repeat;
            height: 300px;
            width: 400px;
            left: -5%;
            bottom: 0;
            background-image: url($assets-path + 'star-orange.svg');
            background-size: cover;
            z-index: -1;
        }
    }
}

// Social icons
.mainCallToAction .socialIcons a:before {
    color: $_orange;
}

// Search
@media (min-width: map-get($map: $breakpoints, $key: lg) + 1) {
    .headerContent {
        position: relative;
    }
    .headerContent .header-search {
        display: flex;
        position: absolute;
        top: 95px;
        right: 47px;
        transform: scale(0.7);
        z-index: 1;
    }
    .pageHeader .headerContent .mainCallToAction {
        flex-grow: 1;
        justify-content: space-around;
    }
}

// Full width feature
.homefeaturecategory-homeboxfullwidth {
    margin: auto;
    max-width: 100vw;
    @media (min-width: 1025px) {
        &:before {
            content: '';
            display: block;
            position: absolute;
            background-size: 100%;
            background-repeat: no-repeat;
            z-index: 0;
            height: 200%;
            width: 70%;
            min-width: 500px;
            left: -20%;
            top: -50%;
            background-image: url($assets-path + 'star-yellow.svg');
        }
    }
    margin-bottom: 3.5rem;
    h2 {
        font-size: 3rem;
    }
    @media (max-width: 1024px) {
        flex-direction: column;
        [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
            position: relative;
            max-width: 100%;
            top: auto;
            bottom: auto;
            transform: none;
        }
    }
}

.homefeaturecategory-homeboxfullwidth:hover [class*="image-link"] .banner {
    transform: unset;
}

.homeFeedBox1 {
    padding-bottom: 3.5rem;
}

// Alternating colours
.listing .listedPost:not(.listedFundraiser):not(.PostCategory_donate-in-memory):nth-child(6n+4), .listing .listedPost:not(.listedFundraiser):not(.PostCategory_donate-in-memory):nth-child(6n+5), .listing .listedPost:not(.listedFundraiser):not(.PostCategory_donate-in-memory):nth-child(6n+6) {
    .listedPostText {
        background-color: $_orange !important;
    }
    * {
      color: $_blue-dark !important;
    }
    &:hover {
        .listedPostText {
            background-color: $_yellow !important;
        }
        * {
            color: $_blue-dark !important;
        }
    }
}

// HR
hr {
    border-top: 5px solid $_yellow;
}

// Intro
.homeIntroWrapper {
    padding-bottom: 2rem;
}

// Feeds title
.feedsTitle {
    max-width: 650px;
}

.eventDetails {
    background-color: $_yellow !important;
    padding: 10px !important;
}

// Forms
#customFormContainer .button {
    background-color: $_yellow;
    color: text-contrast($_yellow);
}

.homefeaturecategory-homeboxfullwidth [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
    margin-left: 2rem;
}

// https://raisingit.atlassian.net/browse/IM-917
.contactOfficeMap {
    display: none;
}

// Appeal widgets 
.appealWidgets {
    background-color: transparent !important;
    padding: 0 !important;
}

// Responsive fix
.pageHeader {
    max-width: 100vw;
}

.carouselSlideTitle img {
    min-height: 250px;
}

.carouselControls {
    justify-content: space-between;
}

#menuMain[aria-expanded="true"] {
    display: flex;
    flex-wrap: wrap;
    .mainCallToAction,
    .header-search {
        width: 100%;
    }
    .mainCallToAction {
        order: -1;
    }
}

// Quick giving
.contentBlock .quickGivingPanel .checkboxRadio {
    display: inline-block;
}

// DES-5697
body * {
    scroll-margin-top: 65px;
}

// https://raisingit.atlassian.net/browse/IM-917
.postAside h2,
.postAside h3 {
    font-size: 1.25em;
}

.post .postContent {
    padding-right: $spacer;
}

// https://raisingit.atlassian.net/browse/CR-321
.productPrice {
  color: $black;
}

// float animation
@keyframes float {
	0% {
		transform: scale(1) translatey(0px);
	}
	50% {
		transform: scale(0.8) translatey(-5px);
	}
	100% {
		transform: scale(1) translatey(0px);
	}
}

// Bespoke appeal setup
// @include appeal-field('the-coral-reef-south-west-childrens-research-appeal', 'christmas');

// Bespoke aquarium visual appeal
@include appeal-field($body-class: 'the-coral-reef-south-west-childrens-research-appeal', 
  $background-colour: white,
  $background-image: 'aquarium-bg.svg',
  $post-text-colour: #0C0C0C,
  $comment-text-colour: #000,
  $icon-image: 'fish03.svg',
  $icon-size: 40px,
  $icon-animation: float 4s linear 0s infinite normal,
  $appeal-content-colour: white
);

body.appeal-the-coral-reef-south-west-childrens-research-appeal {
    .shareWrapper {
      display: block;
      float: right;
      margin-top: -150px;
      strong {
        display: inline-flex;
        margin-right: 20px;
        font-size: 1em;
      }
      .shareList {
        display: inline-flex;
      }
    }
    .contentBlockWrapper,
    .postContent {
        @media (min-width: 1324px) {
            max-width: 87.5%;
        }
        @media (min-width: 1440px) {
            max-width: 90%;
        }
        @media (min-width: 1600px) {
            max-width: 92.5%;
        }
        @media (max-width: 1323px) {
            max-width: 97.5%;
        }
    }
    .appealComments {
        max-width: 100%;
    }
    .headerText{
        text-align: center; 
        padding-top: 100px;
        .title {
            display: none;
        }
        > * {
            color: white;
        }
        > p {
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .appealActionsWrapper .appealActions {
        justify-content: center;
        .button {
            color: white;
            background: $brand-primary;
            border-color: $brand-primary;
            &:hover {
                color: white;
                background: #cc3a00;
                border-color: #cc3a00;
            }
        }
    }
    .commentsListWrapper .commentsList {
        margin: 0 auto 350px;
        min-height: 330px;
        @media (max-width: 1024px) {
            margin: 0 auto 300px;
        }
    }
    .commentsList {
        li.commentDonation {
            &:nth-of-type(8n+1):before {
                background-image: url($assets-path + 'fish01.svg');
                width: 38.39px;
                height: 32.81px;
            }
            &:nth-of-type(8n+2):before {
                background-image: url($assets-path + 'fish02.svg');
                width: 39.99px;
                height: 28.81px;
            }
            &:nth-of-type(8n+3):before {
                background-image: url($assets-path + 'fish03.svg');
                width: 47.18px;
                height: 36.01px;
            }
            &:nth-of-type(8n+4):before {
                background-image: url($assets-path + 'fish04.svg');
                width: 78.37px;
                height: 32.81px;
            }
            &:nth-of-type(8n+5):before {
                background-image: url($assets-path + 'fish05.svg');
                width: 38.39px;
                height: 32.81px;
            }
            &:nth-of-type(8n+6):before {
                background-image: url($assets-path + 'fish06.svg');
                width: 39.99px;
                height: 28.81px;
            }
            &:nth-of-type(8n+7):before {
                background-image: url($assets-path + 'fish07.svg');
                width: 47.18px;
                height: 36.01px;
            }
            &:nth-of-type(8n+8):before {
                background-image: url($assets-path + 'fish08.svg');
                width: 78.37px;
                height: 32.81px;
            }      
        }
    }
    .donationControls,
    ul.commentsList--printed {
        max-width: 1170px;
        margin: 0 auto;
        padding: 0 15px;
    }
    #background-bubbles {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 95vw;
        height: 750px;
    }
    @keyframes animateBubble {
        0% {
            margin-top: 1000px;
        }
        100% {
            margin-top: -100%;
        }
    }
    @keyframes sideWays { 
        0% { 
            margin-left: 0px;
        }
        100% { 
            margin-left: 50px;
        }
    }
    .x1 {
        top: 5%;
        left: -5%;
        transform: scale(0.6);
        animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate;   
    }
    .x2 {
        top: 80%;
        left: 5%;
        transform: scale(0.4);   
        animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate;
    }
    .x3 {
        top: 40%;
        left: 10%;
        transform: scale(0.7);
        animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate;   
    }
    .x4 {
        top: 0;   
        left: 20%;
        transform: scale(0.3);
        animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
    }
    .x5 {
        top: 50%;
        left: 30%;
        transform: scale(0.5);
        animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;   
    }
    .x6 {
        top: 0;
        left: 50%;
        transform: scale(0.8);
        animation: animateBubble 21s linear infinite, sideWays 2s ease-in-out infinite alternate;   
    }
    .x7 {
        top: 70%;
        left: 65%;
        transform: scale(0.4);
        animation: animateBubble 20s linear infinite, sideWays 2s ease-in-out infinite alternate;   
    }
    .x8 {
        top: 10%;
        left: 80%;
        transform: scale(0.3);
        animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
    }
    .x9 {
        top: 50%;
        left: 90%;
        transform: scale(0.6);
        animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;
    }
    .x10 {
        top: 80%;
        left: 80%;
        transform: scale(0.3);
        animation: animateBubble 26s linear infinite, sideWays 2s ease-in-out infinite alternate;
    }
    .bubble {
        border-radius: 50%;
        height: 42px;
        width: 42px;
        position: absolute;
        box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);
        background: radial-gradient(ellipse at center,  rgba(239, 246, 248, 0.59) 0%,rgba(255, 255, 255, 0) 70%);
    }
    .bubble:before {
        content: "";
        position: absolute;
        top: 6px;
        right: 7px;
        width: 16px;
        height: 16px;
        border-radius: 50%;   
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(216, 237, 228, 1);
    }
    .bubble:after {
        content: "";
        position: absolute;
        left: 1px;
        width: 40px;
        height: 40px;
        border-radius: 50%;   
        box-shadow: inset 0px 10px 30px 5px rgba(239, 246, 248, 0.59);
        
    }
    #bodyForm {
        background-position: left 0; /* ensure treasure chest not repeated at most screen sizes */
        overflow: hidden;
        background-size: 1024px auto;
        @media (max-width: 1024px) {
          background-size: 768px auto;
        }
        @media (max-width: 768px) {
          background-position: right 0; /* ensure the treasure chest in view */
        }
    }
}


// Mobile layout increase Font size Bug 351017 //
@media (max-width: map-get($breakpoints, md)) {
    p {
        font-size: 0.9rem;
    }
}
  
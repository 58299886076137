// When a fundraiser is viewing their appeal, they have some controls
// at the bottom of the page where they can edit the description etc.

.dashboardPanel {
  .tabbedListingTitle {
    margin-bottom: $gap-width;
  }

  .accordianHeader {
    padding: 1rem;
    margin-bottom: $gap-width / 2;
    h2 {
      font-size: 1rem;
    }

    .buttonIcon {
      width: 24px;
      height: 24px;
      right: 1rem;
    }

    .buttonIcon:before {
      font-size: .85rem;
    }
  }

  .dashDiv {
    padding: 0 1rem;
    margin: 0 0 $gap-width;
  }
}

#fundraiserDescriptionEdit {
  min-height: 240px;
}

// 'Change the picture at the top of your page'
.fundraiserPictureWrapper {
  padding-bottom: 15px;
  display: grid;
  grid-template-columns: 100px auto 1fr;
  grid-template-rows: auto auto auto;
  column-gap: 15px;

  @include max-width(sm) {
    display: flex;
    flex-direction: column;
  }

  #uploadedImage {
    grid-column: 1 / 2;
    grid-row: 1 / 4;
  }

  .dashInstruction {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
  }

  .uploadifive-button {
    margin: 5px 0;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  // When the user selects an image from their file browser,
  // nothing seemingly happens. This file queue progress thing is
  // set to display: none inline... So there is no response at all
  // Let's reveal it.
  #fileQueue {
    display: block !important;
    margin: 12px 0;
    grid-column: 2 / 4;
    grid-row: 3 / 4;
  }
}

// Let's just permanently expand (and remove the header) of the main
// 'Edit your fundraiser page settings' section.
.dashboardSection.editDescription {
  .accordianHeader {
    display: none;
  }

  .dashDiv.hidden {
    display: block !important;
  }

  .dashDiv {
    padding: 0;
  }
}

// If we've disabled share at the bottom of posts
// we still want share options here for the fundraiser
@if $share-enabled == false {
  .dashboardSection.sharePage .shareWrapper {
    display: block;
  }
}

.dashInstruction {
  display: block;
  margin-bottom: .5em;
}
table.gridTable {
  margin: ($spacer * 2) 0;
  width: 100% !important;
  height: auto !important;
  border: 0 !important;

  tbody {
    border: 0;
    width: 100% !important;
    height: auto !important;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: $table-cell-padding;
    grid-row-gap: $table-cell-padding * 2;
  }

  tr {
    border: 0 !important;
    width: 100% !important;
    height: auto !important;
    display: flex;
    flex-direction: column;
  }

  th, td {
    width: 100% !important;
    height: auto !important;
    border: 0 !important;
    padding: 0 !important;
  }
}

.gridTable--central {
  text-align: center;
}

@media (max-width: map-get($breakpoints, md)) {
  table.gridTable tbody {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: map-get($breakpoints, sm)) {
  table.gridTable tbody {
    grid-template-columns: 1fr;
  }
}

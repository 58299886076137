@mixin cards-layout-prefab($number-of-cards, $prefab, $card-min-width: $card-min-width) {
  @include min-width(sm) {
    // 2 CARD LAYOUTS
    @if $number-of-cards == 2 {
      display: flex;
      flex-wrap: wrap;

      .homeFeature,
      .feedItem {
        flex-grow: 1;
        min-width: $card-min-width;

        // 2-1
        @if $prefab == 1 {
          flex: 1; // Side by side evenly
        }

        // 2-2
        @if $prefab == 2 {
          width: 100%; // On top of each other
        }

        // 2-3
        @if $prefab == 3 {
          flex: 1;

          &:nth-child(1) {
            flex: 2;
          }
        }

        // 2-4
        @if $prefab == 4 {
          flex: 1;

          &:nth-child(2) {
            flex: 2;
          }
        }
      }

      @include prefab-warn($prefab, 4);
    }

    // 3 CARD LAYOUTS
    @if $number-of-cards == 3 {
      // Flex layouts
      @if $prefab >= 1 and $prefab < 13 {
        display: flex;
        flex-wrap: wrap;

        .homeFeature,
        .feedItem {
          flex-grow: 1;
          min-width: $card-min-width;

          // 3-1
          @if $prefab == 1 {
            flex: 1;
          }

          // 3-2
          @if $prefab == 2 {
            &:nth-child(1) {
              flex: 2;
            }

            &:nth-child(2), &:nth-child(3) {
              flex: 1;
              flex-shrink: 0;
            }
          }

          // 3-3
          @if $prefab == 3 {
            &:nth-child(1), &:nth-child(2) {
              flex: 1;
              flex-shrink: 0;
            }

            &:nth-child(3) {
              flex: 2;
            }
          }

          // 3-4
          @if $prefab == 4 {
            &:nth-child(1) {
              width: 100%;
            }

            &:nth-child(2), &:nth-child(3) {
              flex: 1;
            }
          }

          // 3-5
          @if $prefab == 5 {
            &:nth-child(1), &:nth-child(2) {
              flex: 1;
            }

            &:nth-child(3) {
              width: 100%;
            }
          }

          // 3-6
          @if $prefab == 6 {
            &:nth-child(1) {
              width: 100%;
            }

            &:nth-child(2) {
              flex: 2;
            }

            &:nth-child(3) {
              flex: 1;
            }
          }

          // 3-7
          @if $prefab == 7 {
            &:nth-child(1) {
              width: 100%;
            }

            &:nth-child(2) {
              flex: 1;
            }

            &:nth-child(3) {
              flex: 2;
            }
          }

          // 3-8
          @if $prefab == 8 {
            &:nth-child(1) {
              flex: 2;
            }

            &:nth-child(2) {
              flex: 1;
            }

            &:nth-child(3) {
              width: 100%;
            }
          }

          // 3-9
          @if $prefab == 9 {
            &:nth-child(1) {
              flex: 1;
            }

            &:nth-child(2) {
              flex: 2;
            }

            &:nth-child(3) {
              width: 100%;
            }
          }

          // 3-10
          @if $prefab == 10 {
            &:nth-child(1),
            &:nth-child(2) {
              flex: 2;
            }

            &:nth-child(3) {
              flex: 1;
            }
          }

          // 3-11
          @if $prefab == 11 {
            &:nth-child(1) {
              flex: 1;
            }

            &:nth-child(2),
            &:nth-child(3) {
              flex: 2;
            }
          }

          // 3-12
          // This is just everything 100% wide so no need to code anything

        }
      }

      // Grid layouts
      @if $prefab >= 13 {
        @if $warn == true {
          @warn "This home features template is not self-healing!";
        }

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr 1fr;

        .homeFeature,
        .feedItem {
          // TODO: These need to be responsive
          // 3-13
          @if $prefab == 13 {
            &:nth-child(1) {
              grid-column: 1 / 9;
              grid-row: 1 / 3;
            }

            &:nth-child(2) {
              grid-column: 9 / 13;
              grid-row: 1 / 2;
            }

            &:nth-child(3) {
              grid-column: 9 / 13;
              grid-row: 2 / 3;
            }
          }

          // 3-14
          @if $prefab == 14 {
            &:nth-child(1) {
              grid-column: 1 / 5;
              grid-row: 1 / 2;
            }

            &:nth-child(2) {
              grid-column: 1 / 5;
              grid-row: 2 / 3;
            }

            &:nth-child(3) {
              grid-column: 5 / 13;
              grid-row: 1 / 3;
            }
          }
        }
      }

      @include prefab-warn($prefab, 14);
    }

    // 4 CARD LAYOUTS
    @if $number-of-cards == 4 {
      // Flex layouts
      @if $prefab < 9 {
        display: flex;
        flex-wrap: wrap;

        .homeFeature,
        .feedItem {
          flex-grow: 1;
          min-width: $card-min-width;

          // 4-1 - all in a row
          @if $prefab == 1 {
            flex: 1;
          }

          // 4-2 - 2x2
          @if $prefab == 2 {
            @include card-prefab-flex-column(2);
          }

          // 4-3
          @if $prefab == 3 {
            flex: 1;

            &:nth-child(1) {
              flex: auto;
              width: calc( (100% - #{$card-gap-width * 2}) * .66 );
            }
          }

          // 4-4
          @if $prefab == 4 {
            flex: 1;

            &:nth-child(2) {
              flex: auto;
              width: calc( (100% - #{$card-gap-width * 2}) * .66 );
            }
          }

          // 4-5
          @if $prefab == 5 {
            flex: 1;

            &:nth-child(1),
            &:nth-child(4) {
              flex: auto;
              width: calc( (100% - #{$card-gap-width * 2}) * .66 );
            }
          }

          // 4-6
          @if $prefab == 6 {
            flex: 1;

            &:nth-child(2),
            &:nth-child(3) {
              flex: auto;
              width: calc( (100% - #{$card-gap-width * 2}) * .66 );
            }
          }

          // 4-7 - 1 then 3
          @if $prefab == 7 {
            flex: 1;

            &:nth-child(1) {
              @include card-prefab-flex-column(1);
            }
          }

          // 4-8 - 3 then 1
          @if $prefab == 8 {
            flex: 1;

            &:nth-child(4) {
              @include card-prefab-flex-column(1);
            }
          }
        }
      }

      // Grid layouts
      @if $prefab >= 9 {
        @if $warn == true {
          @warn "This home features template is not self-healing!";
        }

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(3, 1fr);

        .homeFeature,
        .feedItem {

          // 4-9
          @if $prefab == 9 {
            grid-column: 9 / 13;

            &:nth-child(1) {
              grid-column: 1 / 9;
              grid-row: 1 / 4;
            }

            &:nth-child(2) {
              grid-row: 1 / 2;
            }

            &:nth-child(3) {
              grid-row: 2 / 3;
            }

            &:nth-child(4) {
              grid-row: 3 / 4;
            }
          }

          // 4-10
          @if $prefab == 10 {
            grid-column: 1 / 5;

            &:nth-child(1) {
              grid-row: 1 / 2;
            }

            &:nth-child(2) {
              grid-row: 2 / 3;
            }

            &:nth-child(3) {
              grid-row: 3 / 4;
            }

            &:nth-child(4) {
              grid-column: 5 / 13;
              grid-row: 1 / 4;
            }
          }
        }
      }

      @include prefab-warn($prefab, 10);
    }

    // 5 CARD LAYOUTS
    @if $number-of-cards == 5 {
      // Flex layouts
      @if $prefab < 10 {
        display: flex;
        flex-wrap: wrap;

        .homeFeature,
        .feedItem {
          flex-grow: 1;
          min-width: $card-min-width;

          // 5-1
          @if $prefab == 1 {
            flex: 1;
          }

          // 5-2 - 2 then 3
          @if $prefab == 2 {
            @include card-prefab-flex-column(3);

            &:nth-child(1), &:nth-child(2) {
              @include card-prefab-flex-column(2);
            }
          }

          // 5-3 - 3 then 2
          @if $prefab == 3 {
            @include card-prefab-flex-column(3);

            &:nth-child(4), &:nth-child(5) {
              @include card-prefab-flex-column(2);
            }
          }

          // 5-4 - 1 then 2 then 2
          @if $prefab == 4 {
            @include card-prefab-flex-column(2);

            &:nth-child(1) {
              @include card-prefab-flex-column(1);
            }
          }

          // 5-5 - 2 then 2 then 1
          @if $prefab == 5 {
            @include card-prefab-flex-column(2);

            &:nth-child(5) {
              @include card-prefab-flex-column(1);
            }
          }
        }
      }

      // Grid layouts
      // TODO

      @include prefab-warn($prefab, 5);
    }

    // 6 CARD LAYOUTS
    @if $number-of-cards == 6 {

      // Flex layouts
      @if $prefab >= 1 and $prefab < 8 {
        display: flex;
        flex-wrap: wrap;

        .homeFeature,
        .feedItem {
          flex-grow: 1;
          min-width: $card-min-width;

          // 6-1
          @if $prefab == 1 {
            flex: 1;
          }

          // 6-2
          @if $prefab == 2 {
            // using flex-basis growing up to create a flexible grid here
            @include card-prefab-flex-column(4)

            &:nth-child(1), &:nth-child(2) {
              @include card-prefab-flex-column(2)
            }
          }

          @if $prefab == 3 {
            @include card-prefab-flex-column(3)
          }

          @if $prefab == 4 {
            @include card-prefab-flex-column(2)
          }

          @if $prefab == 5 {
            @include card-prefab-flex-column(3)

            &:nth-child(1) {
              @include card-prefab-flex-column(1)
            }

            &:nth-child(2), &:nth-child(3) {
              @include card-prefab-flex-column(2)
            }
          }

          @if $prefab == 6 {
            @include card-prefab-flex-column(3)

            &:nth-child(1), &:nth-child(2) {
              @include card-prefab-flex-column(2)
            }

            &:nth-child(3) {
              @include card-prefab-flex-column(1)
            }
          }

          @if $prefab == 7 {
            @include card-prefab-flex-column(3)

            &:nth-child(6) {
              @include card-prefab-flex-column(1)
            }
          }
        }
      }

      // Grid layouts
      @if $prefab >= 8 {
        @if $warn == true {
          @warn "This home features template is not self-healing!";
        }

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(4, auto);

        .homeFeature,
        .feedItem {
          // top 3 staggered pattern
          @if $prefab == 8 or $prefab == 10 {
            &:nth-child(1) {
              grid-column: 1 / 7;
              grid-row: 1 / 3;
            }

            &:nth-child(2) {
              grid-column: 7 / 13;
              grid-row: 1 / 2;
            }

            &:nth-child(3) {
              grid-column: 7 / 13;
              grid-row: 2 / 3;
            }
          }

          // bottom 3 staggered pattern
          @if $prefab == 8 or $prefab == 11 {
            &:nth-child(4) {
              grid-column: 1 / 7;
              grid-row: 3 / 4;
            }

            &:nth-child(5) {
              grid-column: 1 / 7;
              grid-row: 4 / 5;
            }

            &:nth-child(6) {
              grid-column: 7 / 13;
              grid-row: 3 / 5;
            }
          }

          // 6-9 - Inverted 6-8
          @if $prefab == 9 {
            @debug here;
            &:nth-child(1) {
              grid-column: 1 /  7;
              grid-row: 1 / 2;
            }

            &:nth-child(2) {
              grid-column: 1 / 7;
              grid-row: 2 / 3;
            }

            &:nth-child(3) {
              grid-column: 7 / 13;
              grid-row: 1 / 3;
            }

            &:nth-child(4) {
              grid-column: 1 / 7;
              grid-row: 3 / 5;
            }

            &:nth-child(5) {
              grid-column: 7 / 13;
              grid-row: 3 / 4;
            }

            &:nth-child(6) {
              grid-column: 7 / 13;
              grid-row: 4 / 5;
            }
          }

          // 6-10 - bottom 3 even, top 3 staggered
          @if $prefab == 10 {
            &:nth-child(n+4) {
              grid-column: span 4;
            }
          }

          // 6-11 - top 3 even, bottom 3 staggered
          @if $prefab == 11 {
            &:nth-child(-n+3) {
              grid-column: span 4;
            }
          }
        }
      }

      @include prefab-warn($prefab, 11);
    }
  }
}

$manager-grey: #33454f;
$manager-highlight: #43afe3; // #ec008c;

.adminBar,
.adminBarEdit {
  position: relative; // Stacking context
  font-size: 13px;
  padding: 10px 20px;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: $manager-grey;
  color: #fff;
}

.adminBar {
  float: right;

  .adminName {
    opacity: .75;
    margin-right: 20px;
  }

  a {
    color: $manager-highlight;
  }
}

// We want to specify some select styles here OUTSIDE the scope of the client's variables
// as this is technically manager chrome
#postSelect {
  padding: 8px;
  border: 0;
  border-radius: 0;
  color: #2b3236;
  line-height: 1.25;
  font-size: 16px;
  margin-right: 10px;
  border-radius: 4px;
}

.button.managerEditBtn {
  padding: 10px 20px;
  background-color: $manager-highlight;
  color: #fff;
  border-radius: 4px;
  text-transform: none;
  font-weight: 700;
  line-height: inherit;
  width: auto;
  box-shadow: none;
  margin: 0;
  transition: all .2s ease-in-out;

  &:hover {
    background-color: darken($manager-highlight, 10%);
  }
}

@mixin postApproval($text-colour, $background-colour) {
  background-color: $background-colour;
  border: 1px solid darken($background-colour, 10%);
  color: $text-colour;
}

.editPostCat {
  display: flex;
  align-items: center;

  .formQuestion {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin-right: 10px;
    padding: 5px 8px;
    border-radius: 4px;

    input[type="radio"] {
      margin-right: 5px;
    }

    label {
      margin: 0;
    }
  }

  // Pending
  .formQuestion:nth-child(1) {
    @include postApproval($warning-colour--on-background, $warning-colour--background);
  }

  // Approved
  .formQuestion:nth-child(2) {
    @include postApproval($success-colour--on-background, $success-colour--background);
  }

  // Rejected
  .formQuestion:nth-child(3) {
    @include postApproval($danger-colour--on-background, $danger-colour--background);
  }
}

.editPostDetails {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;

  .button {
    order: -1;
    margin-right: 20px;
  }

  dl, dd {
    margin: 0;
  }

  dl {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-column-gap: 10px;
  }

  dt {
    text-align: right;
    opacity: .75;
  }

}

// On the homepage, we can select items to edit
.highlighted {
  outline: $brand-primary dashed thick !important; // Overwrite inline style here
}
@if $breadcrumb-enabled == false {
  .breadcrumbWrapper {
    display: none;
  }
} @else {
  .breadcrumbWrapper {
    width: 100%;
    max-width: $breadcrumb-max-width;
    padding: 0 $site-bleed;
    margin-left: auto;
    margin-right: auto;
  }

  .breadcrumbList {
    @include list-unstyled;
    display: flex;
    flex-wrap: wrap;
    margin-top: $breadcrumb-margin-top;
    margin-bottom: $breadcrumb-margin-bottom;

    @if $breadcrumb-text-align == center {
      justify-content: center;
    }

    @if $breadcrumb-text-align == right {
      justify-content: flex-end;
    }
  }

  .breadcrumbItem {
    display: flex;
    align-items: baseline;

    @if $breadcrumb-text-align == left {
      margin-right: .5em;
    }

    @if $breadcrumb-text-align == center {
      margin-left: .25em;
      margin-right: .25em;
    }

    @if $breadcrumb-text-align == right {
      margin-left: .5em;
    }

    &:before {
      content: '\f054';
      font-family: $icon-font-family;
      font-size: .75em;
      margin-right: .75em;
      color: $grey-light;
    }

    &:first-child:before {
      display: none;
    }
  }

  @if $breadcrumb-disabled-on-listing == true {
    .listing .breadcrumbWrapper {
      display: none;
    }
  }
}


//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );

  // Choose your card style for home feature cards
  .homeFeature {
    @include card (
      $card-text-align: center
    );
    @include card-basic;
  }
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.

.homefeaturecategory-homeboxfullwidth {
  @include card (
    $card-gap-width: 0,
    $card-details-background-colour: transparent,
    $card-hover-details-background-colour: transparent,
    $card-summary-colour: #0F1733,
    $card-heading-colour: #0F1733,
    $card-hover-summary-colour: #0F1733,
    $card-hover-heading-colour: #0F1733,
    $card-hover-image-scale: 1
  );
  @include card-text-overlay (
    $card-text-overlay-show-summary-on-hover: false,
    $card-text-overlay-summary-enabled: true,
    $card-text-overlay-details-max-width: 400px,
    $card-text-overlay-details-position-y: center,
    $card-text-overlay-details-position-x: left
  );
}

//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 1
);

// Specify a card layout for each individual feed
.homeFeedBox1 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
  .feedItem {
    @include card(
      $card-summary-enabled: false,
      $card-details-background-colour: $_orange,
      $card-text-align: center,
      $card-hover-details-background-colour: $_yellow
    );
    @include card-basic;
  }
}

.homeFeedBox2 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
  .feedItem {
    @include card (
      $card-details-background-colour: $white,
      $card-hover-details-background-colour: $white,
      $card-heading-colour: $_orange,
      $card-hover-heading-colour: $_orange,
      $card-summary-colour: $body-colour,
      $card-hover-summary-colour: $body-colour,
    );
    @include card-basic;
  }
}

.homeFeedBox3 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
  .feedItem {
    @include card (
      $card-details-background-colour: $_yellow,
      $card-hover-details-background-colour: $_yellow,
      $card-heading-colour: $body-colour,
      $card-hover-heading-colour: $body-colour,
      $card-summary-colour: $body-colour,
      $card-hover-summary-colour: $body-colour,
    );
    @include card-basic;
  }
}

// Choose your card style for home feed cards



//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem):not(.PostCategory_your-stories):not(.PostCategory_donate-in-memory){
  @include card;
  @include card-basic;
}

.listedPost.PostCategory_your-stories {
  @include card (
      $card-details-background-colour: $grey-lightest,
      //$card-hover-details-background-colour: $grey-lightest,
      $card-heading-colour: $_orange,
      //$card-hover-heading-colour: $_orange,
      //$card-summary-colour: $body-colour,
      //$card-hover-summary-colour: $body-colour,
    );
  @include card-basic;
}

.listedPost.PostCategory_donate-in-memory {
  @include card (
      $card-details-background-colour: #f4efdb,
      //$card-hover-details-background-colour: $grey-lightest,
      $card-heading-colour: $_orange,
      //$card-hover-heading-colour: $_orange,
      //$card-summary-colour: $body-colour,
      //$card-hover-summary-colour: $body-colour,
    );
  @include card-basic;
  
}

.listedPost.module-blog {
  @include card (
      $card-details-background-colour: $_yellow,
      $card-hover-details-background-colour: $_yellow,
      $card-heading-colour: $body-colour,
      $card-hover-heading-colour: $body-colour,
      $card-summary-colour: $body-colour,
      $card-hover-summary-colour: $body-colour,
    );
  @include card-basic;
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above
// you can remove the ':not(.listedProduct)' selector above and squash this
// together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}
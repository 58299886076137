// Whilst authentication is handled using Altruistic Identity, we still have some pages on the site that need styling up
// E.g. password reset, user registration etc.

body[class^='lobby'] {
  #bodyForm {
    @include parentless-container-wrapper($post-content-max-width);
    display: flex;
    flex-direction: column;
    margin-top: $gap-width * 2;
    margin-bottom: $gap-width * 2;
  }
}

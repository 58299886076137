// Reset some borders here used for custom and contact forms
.paymentPage,
.formDonatePage {
  .formWrapper fieldset {
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
  }
}

.paymentPage {
  .contentBlockWrapper {
    margin: 0;
  }

  .headerText {
    padding-top: 0;
  }
}

// Card start/end date field groups
.paymentForm .formQuestion.cardStartDate,
.paymentForm .formQuestion.cardEndDate {
  input, select {
    flex-grow: 0;
    width: 100px;
  }

  *[id*='Month'] {
    margin-right: 15px;
  }

  *[id*='Year'] {
    margin-left: 15px;
  }
}


@include min-width(md) {
  // Card security code
  .paymentForm .formQuestion.cardSecurityCode input {
    flex-grow: 0;
    width: 235px;
  }

  // Postcode look up button(s)
  .paymentForm .postcode-lookup-buttons {
    margin-left: calc(#{$field-group-label-width} + 0.5rem);
  }
}

@include max-width(md) {
  .paymentForm .formQuestion.cardStartDate input,
  .paymentForm .formQuestion.cardEndDate select {
    flex-grow: 1;
  }
}

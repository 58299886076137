// Todo - mega menu

.menuMain .topLevel {
  font-family: $nav-font-family;
  text-transform: $nav-text-transform;

  li {
    display: flex;
    flex-wrap: wrap;
  }

  // By default set the link style to all be subnav, opt in desktop top level further down
  a {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: $nav-submenu-item-padding;
    font-family: $nav-submenu-item-font-family;
    font-size: $nav-submenu-item-font-size;
    font-weight: $nav-submenu-item-font-weight;
    color: $nav-submenu-item-colour;

    &:hover {
      text-decoration: none;
    }
  }
}

.menuMain ul {
  @include list-unstyled;
}

.menuMain li.menuAdminAltItems {
  display: none; // Hide admin and basket items by default
}

// Reveal admin menu in nav if breakpoint specified
@if $menu-admin-to-nav-breakpoint != 0px {
  @media (max-width: $menu-admin-to-nav-breakpoint) {
    #menuAdmin {
      display: none;
    }

    .menuMain .menuAdminAltItems {
      display: list-item;
    }

    // Loose basket icon shop item
    @if $shop-enabled == true {
      .menuAdminBasket.menuAdminAltItems:before {
        display: none;
      }
    }
  }
}

.subMenu {
  width: 100%;
  display: none; // Set initial state here
  background-color: $nav-submenu-background-colour;
}

@media (max-width: $nav-breakpoint) {
  .menuMain.menuMain--mobile:not(.active) {
    visibility: hidden;
  }
}

@media (min-width: $nav-breakpoint + 1px) {
  .subMenu {
    width: $nav-submenu-width;
    box-shadow: $nav-submenu-box-shadow;

    &.level2 {
      border-radius: 0 0 $nav-submenu-border-radius $nav-submenu-border-radius;
    }

    &:not(.level2) {
      border-radius: 0 0 $nav-submenu-border-radius 0;
    }
  }
}
